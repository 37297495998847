import React, { useState, useEffect } from 'react';
import "./ThemeToggleButton.css";

const ThemeToggle = () => {
    // Get the theme from localStorage or default to 'light'
    const getTheme = () => localStorage.getItem('theme') || 'light';

    // Track theme state globally using localStorage
    const [currentTheme, setCurrentTheme] = useState(getTheme());

    // Set the theme in both localStorage and apply the appropriate class to the document
    const setTheme = (theme) => {
        localStorage.setItem('theme', theme);
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    // Sync the local state with the theme stored in localStorage on mount
    useEffect(() => {
        const storedTheme = getTheme();
        if (storedTheme !== currentTheme) {
            setCurrentTheme(storedTheme);
        }
    }, []);

    // Apply the theme whenever currentTheme changes
    useEffect(() => {
        setTheme(currentTheme);
    }, [currentTheme]);

    // Toggle between 'light' and 'dark' themes
    const toggleTheme = () => {
        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        setCurrentTheme(newTheme);
    };

    // Keyboard shortcut for toggling theme (e.g., "Ctrl + T")
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ( event.key === 't') {
                event.preventDefault();
                toggleTheme();
            }
            // if (event.ctrlKey && event.key === 't') {
            //     event.preventDefault();
            //     toggleTheme();
            // }
        };

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentTheme]);

    // Sync theme changes across tabs/windows when localStorage is updated
    useEffect(() => {
        const handleStorageChange = () => {
            const updatedTheme = getTheme();
            if (updatedTheme !== currentTheme) {
                setCurrentTheme(updatedTheme);
            }
        };

        // Add event listener to handle storage changes
        window.addEventListener('storage', handleStorageChange);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [currentTheme]);

    return (


        <label className='themeSwitcherThree relative inline-flex cursor-pointer select-none items-center'>
            <div
                onClick={toggleTheme}
                className='shadow-card  items-center justify-center rounded-md  shadow-xl border border-red-300 cursor-pointer'
            >
                {currentTheme === 'light' ? (
                    // Sun icon for light theme
                    <span className='flex h-8 w-8 items-center justify-center rounded text-gray-950 '>
                        <svg
                            width='15'
                            height='15'
                            viewBox='0 0 15 15'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <g clipPath='url(#clip0_3128_692)'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M8 0C8.36819 0 8.66667 0.298477 8.66667 0.666667V2C8.66667 2.36819 8.36819 2.66667 8 2.66667C7.63181 2.66667 7.33333 2.36819 7.33333 2V0.666667C7.33333 0.298477 7.63181 0 8 0ZM8 5.33333C6.52724 5.33333 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 8 10.6667C9.47276 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47276 5.33333 8 5.33333ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8ZM8.66667 14C8.66667 13.6318 8.36819 13.3333 8 13.3333C7.63181 13.3333 7.33333 13.6318 7.33333 14V15.3333C7.33333 15.7015 7.63181 16 8 16C8.36819 16 8.66667 15.7015 8.66667 15.3333V14ZM2.3411 2.3424C2.60145 2.08205 3.02356 2.08205 3.28391 2.3424L4.23057 3.28906C4.49092 3.54941 4.49092 3.97152 4.23057 4.23187C3.97022 4.49222 3.54811 4.49222 3.28776 4.23187L2.3411 3.28521C2.08075 3.02486 2.08075 2.60275 2.3411 2.3424ZM12.711 11.7682C12.4506 11.5078 12.0285 11.5078 11.7682 11.7682C11.5078 12.0285 11.5078 12.4506 11.7682 12.711L12.7148 13.6577C12.9752 13.918 13.3973 13.918 13.6577 13.6577C13.918 13.3973 13.918 12.9752 13.6577 12.7148L12.711 11.7682ZM0 8C0 7.63181 0.298477 7.33333 0.666667 7.33333H2C2.36819 7.33333 2.66667 7.63181 2.66667 8C2.66667 8.36819 2.36819 8.66667 2 8.66667H0.666667C0.298477 8.66667 0 8.36819 0 8ZM14 7.33333C13.6318 7.33333 13.3333 7.63181 13.3333 8C13.3333 8.36819 13.6318 8.66667 14 8.66667H15.3333C15.7015 8.66667 16 8.36819 16 8C16 7.63181 15.7015 7.33333 15.3333 7.33333H14ZM4.23057 11.7682C4.49092 12.0285 4.49092 12.4506 4.23057 12.711L3.28391 13.6577C3.02356 13.918 2.60145 13.918 2.3411 13.6577C2.08075 13.3973 2.08075 12.9752 2.3411 12.7148L3.28776 11.7682C3.54811 11.5078 3.97022 11.5078 4.23057 11.7682ZM13.6577 3.28521C13.918 3.02486 13.918 2.60275 13.6577 2.3424C13.3973 2.08205 12.9752 2.08205 12.7148 2.3424L11.7682 3.28906C11.5078 3.54941 11.5078 3.97152 11.7682 4.23187C12.0285 4.49222 12.4506 4.49222 12.711 4.23187L13.6577 3.28521Z'
                                    fill='currentColor'
                                />
                            </g>
                            <defs>
                                <clipPath id='clip0_3128_692'>
                                    <rect width='15' height='16' fill='white' />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                ) : (
                    // Moon icon for dark theme
                    <span className='flex h-9 w-9 items-center justify-center rounded  text-gray-50'>
                        <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M8.0547 1.67334C8.18372 1.90227 8.16622 2.18562 8.01003 2.39693C7.44055 3.16737 7.16651 4.11662 7.23776 5.07203C7.30901 6.02744 7.72081 6.92554 8.39826 7.60299C9.07571 8.28044 9.97381 8.69224 10.9292 8.76349C11.8846 8.83473 12.8339 8.5607 13.6043 7.99122C13.8156 7.83502 14.099 7.81753 14.3279 7.94655C14.5568 8.07556 14.6886 8.32702 14.6644 8.58868C14.5479 9.84957 14.0747 11.0512 13.3002 12.053C12.5256 13.0547 11.4818 13.8152 10.2909 14.2454C9.09992 14.6756 7.81108 14.7577 6.57516 14.4821C5.33925 14.2065 4.20738 13.5846 3.312 12.6892C2.41661 11.7939 1.79475 10.662 1.51917 9.42608C1.24359 8.19017 1.32563 6.90132 1.75584 5.71037C2.18605 4.51942 2.94659 3.47562 3.94831 2.70108C4.95004 1.92655 6.15164 1.45333 7.41253 1.33685C7.67419 1.31267 7.92565 1.44444 8.0547 1.67334ZM5.58944 4.38848C4.92306 4.9239 4.42155 5.62804 4.12382 6.43416C3.82609 7.24027 3.74517 8.11955 3.89057 8.97395C4.03596 9.82835 4.40122 10.6278 4.94302 11.2696C5.48481 11.9114 6.18018 12.3658 6.95444 12.583C7.7287 12.8001 8.54677 12.7691 9.30694 12.4931C10.0671 12.217 10.7352 11.7092 11.2203 11.0327C10.2827 11.2259 9.30354 11.0748 8.44819 10.6032C7.59284 10.1316 6.91051 9.37014 6.51088 8.43892C6.11124 7.5077 6.01587 6.45878 6.24214 5.45163C6.46842 4.44448 7.00274 3.53307 7.76106 2.85997C7.08067 3.13528 6.42875 3.55258 5.8437 4.08803L5.58944 4.38848Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                )}
            </div>
        </label>


    );
};

export default ThemeToggle;
