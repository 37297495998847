import PropTypes from "prop-types";
import { useState } from "react";
import styles from "./frame-component4.module.css";

const FrameComponent4 = ({ className = "" }) => {
  // Testimonial data array
  const testimonials = [
    {
      stars: 5,
      quote:
      "The university’s interior design has been transformed into a vibrant, modern space that enhances both student engagement and learning. The attention to detail and the overall aesthetic have exceeded our expectations. We are thoroughly impressed with the results!",
      name: "Pimpri Chinchwad University",
      position: "Council",
      icon: "/logos/pcmcuniversity.png",
      iconTitle: "University Interior",
    },
    {
      stars: 5,
      quote:
      "The clinic’s interior design has drastically improved the functionality and aesthetics of our space. The team worked meticulously to ensure every aspect of the design was tailored to our needs. We are extremely satisfied with the final result and the positive impact it has had on our clinic.",
      name: "Dr. Prashant Khaire",
      position: "Clinic Owner",
      company: "Chinchwad",
      icon: "/logos/hospitalavatar.png",
      iconTitle: "Clinic Interior",
    },
    {
      stars: 5,
      quote:
      "Our hotel’s interior has undergone an absolutely stunning transformation. The designers created a space that blends luxury and comfort, leaving our guests in awe. The feedback has been overwhelmingly positive, and we couldn’t be happier with the outcome.",
      name: "Peperry Kick Hotel",
      position: "Management",
      icon: "/logos/peparrykick.png",
      iconTitle: "Hotel Interior",
    },
    {
      stars: 5,
      quote:
      "The design work for our bank interior is nothing short of excellent. The team was professional and attentive, ensuring that every detail was perfect. We couldn’t be happier with the results, and it has made a noticeable impact on both our staff and clients.",
      name: "Mahaganpati Multistate Bank",
      position: "CEO",
      icon: "/logos/mahaganpati.png",
      iconTitle: "Bank Interior",
    },
    {
      stars: 5,
      quote:
      "Our company’s new outsourcing office looks sleek and modern, providing a professional yet comfortable space for our team and clients. The design reflects our global operations perfectly and has elevated our brand image. We are thrilled with the overall outcome and how well it meets our business needs.",
      name: "Absolute Global Outsourcing",
      position: "CEO",
      icon: "/logos/absoluteglobal.png",
      iconTitle: "Office Interior",
    },

    {
      stars: 5,
      quote:
      "NACL’s office interiors have been designed with precision and care, balancing aesthetics with functionality. The space now perfectly aligns with our company’s vision and culture. From the layout to the finer details, everything was executed flawlessly. We couldn’t have asked for a better result!",
      name: "NACL",
      position: "Management",
      icon: "/logos/nacl.png",
      iconTitle: "Multinational Office Interior",
    },
    {
      stars: 5,
      quote:
      "The redesign of Prathmesh Hospital has been transformative for our clinic. The new interior is both welcoming and functional, offering a comfortable environment for our patients. The design enhances the flow of the clinic, making it easier for our staff to provide efficient care. We’re incredibly happy with the results.",
      name: "Prathmesh Hospital",
      position: "Cancer Specialist",
      icon: "/logos/hospitalavatar.png",
      iconTitle: "Hospital Interior",
    },
    {
      stars: 5,
      quote:
      "The landscape design for JSW Company is absolutely top-notch. The creative approach and flawless execution have turned our premises into a visually stunning space. We are impressed with the attention to detail and how the design reflects our company’s image. It’s a great environment for both employees and visitors.",
      name: "Landscape JSW",
      icon: "/logos/jswlandscape.png",
      iconTitle: "Company Landscape Design",
    },
    {
      stars: 5,
      quote:
      "Shree Jewellers' new interior design is a game-changer for our store. The way the display setups have been arranged has truly elevated the shopping experience for our customers. We’ve received compliments from visitors who love the look and feel of the store. This transformation has significantly enhanced our brand image.",
      name: "Shree Jewellers",
      position: "Store Owner",
      icon: "/logos/shreejwel.png",
      iconTitle: "Jewelry Store Interior",
    },
    {
      stars: 5,
      quote:
      "The electrical layout and interior design for Krishna Enterprises at Spine City Mall were handled with precision and expertise. The design is not only aesthetically pleasing but also practical for our operations. The results speak for themselves, and we are more than satisfied with the work done.",
      name: "Krishna Enterprises",
      icon: "/logos/industryavatar.png",
      iconTitle: "Electrical and Interior",
    },
    {
      stars: 5,
      quote:
      "Sant Pith CBSC School’s interior design has transformed our classrooms into a welcoming and inspiring environment for students. Every aspect of the design was carefully considered, making it both functional and visually appealing. The students love the new space, and we’ve noticed a positive impact on their engagement.",
      name: "Sant Pith CBSC School",
      position: "Principal",
      icon: "/logos/santpith.png",
      iconTitle: "School Interior",
    },
    {
      stars: 5,
      quote:
      "Fuge Prima Mall has been completely revitalized with its new interior design. The redesign has attracted a lot of positive attention, and visitors are impressed with the modern and inviting layout. The transformation has been a significant boost for our business, and we couldn’t be more pleased with the results.",
      name: "Prathmesh Fuge",
      position: "Owner",
      icon: "/logos/mallavatar.png",
      iconTitle: "Mall Interior",
    },
    {
      stars: 5,
      quote:
      "The work done on our hair clinic's interior is phenomenal! The new layout is stylish, comfortable, and functional, making it a welcoming space for our clients. We’ve received a lot of positive feedback, and the clinic now reflects the high-quality services we offer.",
      name: "Dr. Satish",
      position: "Owner",
      icon: "/logos/hospitalavatar.png",
      iconTitle: "Hair Clinic Interior",
    },
    {
      stars: 5,
      quote:
        "The interior design for our residential project exceeded all expectations. The team's attention to detail and creative approach resulted in a space that is not only stylish but also highly functional. Every element reflects our vision perfectly.",
      name: "Nitin Zaware",
      position: "Council Member",
      icon: "/logos/houseavatar.png",
      iconTitle: "House Interior",
  }
  
 
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next testimonial
  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  // Function to go to the previous testimonial
  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <section className={[styles.testimonial11Wrapper, className].join(" ")}>
      <div className={styles.testimonial11}>
        <div className={styles.headingParent}>
          <b className={styles.heading}>Customer testimonials</b>
          <div className={styles.text}>
            {`Have a look at what our clients are saying! We love feedback ♥️`}
          </div>
        </div>
        <div className={styles.contentParent}>
          <div className={styles.content}>
            <div className={styles.container}>
              <div className={styles.stars}>
                {/* Rendering star icons based on the number of stars */}
                {Array.from({ length: currentTestimonial.stars }).map((_, index) => (
                  <img
                    key={index}
                    className={styles.vectorIcon}
                    alt="star"
                    src={`/images/vector-${index + 1}.svg`}
                  />
                ))}
              </div>
              <blockquote className={styles.quote}>
                {currentTestimonial.quote}
              </blockquote>
              <div className={styles.avatar}>
                <div className="flex gap-3">
                  <img
                    className={styles.avatarImageIcon}
                    loading="lazy"
                    alt="avatar"
                    src="../logos/avatar.png"
                  />
                  <div className={styles.avatarContent}>
                    {/* Conditionally render name if it exists */}
                    {currentTestimonial.name && (
                      <b className={styles.clientTestimonial}>
                        {currentTestimonial.name}
                      </b>
                    )}
                    {/* Conditionally render position and company if they exist */}
                    {currentTestimonial.position && (
                      <div className={styles.clientPosition}>
                        <p className="text-wrap">
                          {currentTestimonial.position}
                          {currentTestimonial.company && (
                            <span>, {currentTestimonial.company}</span>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles.content1}>
                  <div className={styles.iconGoogle}>
                    <img
                      className={styles.iconLibrary}
                      alt={currentTestimonial.iconTitle}
                      src={currentTestimonial.icon}
                    />
                    <h1 className={styles.hitchHike}>
                      {currentTestimonial.iconTitle}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slider dots and navigation arrows */}
          <div className={styles.content3}>
            <div className={styles.sliderDotsContainer}>
              <div className={styles.sliderDots}>
                {testimonials.map((_, index) => (
                  <div
                    key={index}
                    className={index === currentIndex ? styles.dotActive : styles.dot}
                  />
                ))}
              </div>
            </div>
            <div className={styles.vectorParent}>
              <img
                className={styles.frameChild}
                alt="previous"
                src="/images/arrow-3-1.svg"
                onClick={prevTestimonial}
              />
              <img
                className={styles.frameItem}
                alt="next"
                src="/images/arrow-2-1.svg"
                onClick={nextTestimonial}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent4.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent4;
