import PropTypes from "prop-types";
import { useState } from "react";
import "./ProjectHero.css";
import Slide from "./Slide";
import ProjectDetails from "./ProjectDetails.jsx"; // Import the ProjectDetails component

const ProjectHero = ({ className = "" }) => {
    const [selectedCategory, setSelectedCategory] = useState("Hospital");
    const [selectedProject, setSelectedProject] = useState(null); // State to store selected project

    const categories = [
        "Hospital",
        "Hotels",
        "College",
        "University",
        "School",
        "Showroom",
        "Residential",
        "Corporate offices",
        "Landscape",
        "Bank",
        "Industrial"
    ];

    //  projects for each category
    const projects = {
        Hospital: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/hospitalavatar.png",
                projectName: "Prathmesh Hospital (Cancer Specialist)",
                description: "A state-of-the-art cancer hospital designed to prioritize patient comfort and treatment efficacy. The facility is equipped with advanced healthcare technology, providing a calm and serene environment to aid patient recovery.",
                type: "Hospital",
                location: "Pune, Maharashtra",
                designStyle: "Modern Healthcare",
                completionDate: "2019",
                background: "/siteimages/bg-hospital1.jpg"
            },
            {
                slideNumberLabels: "2",
                iconslogos: "/logos/hospitalavatar.png",
                projectName: "Dr. Prashant Khaire Clinic",
                description: "An interior space focused on enhancing the patient experience with soothing design elements and optimal functionality. The clinic's layout is created to streamline workflows for healthcare professionals while ensuring patient comfort.",
                type: "Clinic",
                location: "Chinchwad, Maharashtra",
                designStyle: "Contemporary",
                completionDate: "2007",
                background: "/siteimages/bg-hospital2.jpg"
            },
            {
                slideNumberLabels: "3",
                iconslogos: "/logos/hospitalavatar.png",
                projectName: "Dr. Satish - Hair Clinic",
                description: "A stylish and modern hair clinic interior designed to offer both functionality and comfort. The clinic's layout ensures a welcoming atmosphere for clients while maintaining a professional setting that reflects the high standards of care provided.",
                type: "Hair Clinic",
                location: "Pune Camp",
                designStyle: "Modern and Functional",
                completionDate: "2020",
                background: "/siteimages/bg-hospital3.jpg"
            }
            
        ],
        Hotels: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/peparrykick.png",
                projectName: "Peperry Kick Hotel",
                description: "A luxurious hotel designed with a blend of traditional elegance and modern amenities. Each room is crafted to provide comfort while the common areas reflect local culture and sophistication.",
                type: "Hotel",
                location: "Pune, Maharashtra",
                designStyle: "Luxury",
                completionDate: "2019",
                background: "/siteimages/peperry-kick.png"
            }
        ],
        College: [
            // {
            //     slideNumberLabels: "1",
            //     iconslogos: "/images/college-icon.svg",
            //     projectName: "College Project A",
            //     description: "A state-of-the-art college campus with smart classrooms and collaborative spaces for students. The design promotes a conducive learning environment with ample natural light and modern facilities.",
            //     type: "College",
            //     location: "Pune, Maharashtra",
            //     designStyle: "Modern Educational",
            //     completionDate: "2019",
            //     background: "/images/college-bg.png"
            // }
        ],
        University: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/pcmcuniversity.png",
                projectName: "Pimpri Chinchwad University",
                description: "An expansive university complex focusing on sustainability and creating a conducive learning environment. The design incorporates green spaces and innovative architecture to foster student engagement.",
                type: "University",
                location: "Pimpri Chinchwad, Maharashtra",
                designStyle: "Sustainable",
                completionDate: "2023",
                background: "/siteimages/university-bg.png"
            }
        ],
        School: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/santpith.png",
                projectName: "Sant Pith CBSC School",
                description: "A vibrant school designed to encourage creativity and learning with open spaces and green areas. The layout promotes interactive learning with dedicated zones for various activities.",
                type: "School",
                location: "Chikhali, Maharashtra",
                designStyle: "Contemporary Educational",
                completionDate: "2024",
                background: "/siteimages/school1.png"
            }
        ],
        Showroom: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/shreejwel.png",
                projectName: "Shree Jwellers",
                description: "An elegantly designed jewelry showroom that combines aesthetics and functionality. The layout is crafted to provide an immersive customer experience, highlighting the jewelry pieces through expert lighting and display arrangements.",
                type: "Jwellary Showroom",
                location: "Spine City Mall, Pune",
                designStyle: "Elegant and Functional",
                completionDate: "2019",
                background: "/siteimages/shree-jwel-showroom2.png"
            }
            
        ],
        Residential: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/mallavatar.png",
                projectName: "Prathmesh Fuge",
                description: "A luxurious residential complex that blends modern living with elegant design. The project features spacious, well-lit interiors, energy-efficient amenities, and an overall layout that promotes both comfort and style.",
                type: "Residential Complex",
                location: "Fuge Prima Mall, Pune",
                designStyle: "Modern Luxury",
                completionDate: "2019",
                background: "/siteimages/house-5.jpeg"
            },
            {
                slideNumberLabels: "2",
                iconslogos: "/logos/houseavatar.png",
                projectName: "Nitin Zaware - House Interior",
                description: "This residential project showcases a perfect blend of elegance and functionality. Designed with modern aesthetics in mind, it features open living spaces, quality materials, and thoughtful layouts that enhance comfort and livability.",
                background: "/siteimages/house-7.jpeg",
                type: "Residential",
                location: "Park Street, Wakad, Pune",
                completionDate: "2019",
                designStyle: "Modern Elegance"
            }
            
            
        ],
        "Corporate offices": [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/absoluteglobal.png",
                projectName: "Absolute Global Outsourcing",
                description: "An outsourcing office designed to enhance productivity while maintaining a modern aesthetic. The layout promotes collaboration and comfort for employees and clients alike.",
                type: "Corporate Office",
                location: "Pune, Maharashtra",
                designStyle: "Corporate Modern",
                completionDate: "2022",
                background: "/siteimages/absolute-company1.jpeg"
            },
            {
                slideNumberLabels: "2",
                iconslogos: "/logos/nacl.png",
                projectName: "NACL Industries Limited",
                description: "An efficient office design that meets the needs of a fast-paced, multinational business. The space is designed to be both functional and aesthetically pleasing, promoting seamless communication and employee satisfaction.",
                type: "Corporate Office",
                location: "Gujarat, Hyderabad",
                designStyle: "Contemporary Corporate",
                completionDate: "2022",
                background: "/siteimages/nacl-1.jpeg"
            }
            
        ],
        Landscape: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/jswlandscape.png",
                projectName: "Landscape JSW",
                description: "A stunning landscape project that seamlessly integrates nature with urban living. The design enhances the natural beauty of the surroundings while providing functional outdoor spaces.",
                type: "Landscape",
                location: "Gujarat",
                designStyle: "Natural Landscape",
                completionDate: "2022",
                background: "/siteimages/jsw-1.png"
            }
        ],
        Bank: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/mahaganpati.png",
                projectName: "Mahaganpati Multistate Bank",
                description: "A secure and modern bank facility with a focus on both technology and customer experience. The design incorporates advanced security features while maintaining a welcoming atmosphere.",
                type: "Bank",
                location: "Pune, Maharashtra",
                designStyle: "Modern Banking",
                completionDate: "2022",
                background: "/siteimages/bank-1.png"
            }
        ],
        Industrial: [
            {
                slideNumberLabels: "1",
                iconslogos: "/logos/industryavatar.png",
                projectName: "Krishna Enterprises Electrical",
                description: "An industrial space designed for efficiency and operational excellence. The project focuses on creating a functional environment that optimizes workflow while maintaining a modern aesthetic that aligns with the brand’s vision.",
                type: "Industrial",
                location: "Spine City Mall, Pune",
                designStyle: "Modern Industrial",
                completionDate: "2019",
                background: "/siteimages/meeting-room-office.jpeg"
            }
        ]
    };
    


    // Get projects for the selected category
    const displayedProjects = projects[selectedCategory] || [];

    // Function to handle "View Project" button click
    const viewProjectDetails = (project) => {
        setSelectedProject(project); // Set selected project
    };

    // Function to go back to the list
    const goBackToProjects = () => {
        setSelectedProject(null); // Clear selected project
    };

    return (
        <div className={`header-parent ${className}`}>
            {/* Conditionally render ProjectDetails or the project list */}
            {selectedProject ? (
                <ProjectDetails project={selectedProject} onBack={goBackToProjects} />
            ) : (
                <>
                    <div className="header">
                        <div className="header-wrapper1">
                            <div className="header-content">
                                <div className="review-container">
                                    <div className="our-consumers-review">Our consumers review</div>
                                    <div className="kk">
                                        <img className="star-icons" alt="" src="/images/star-icons.svg" />
                                        <img className="star-icons" alt="" src="/images/frame-1.svg" />
                                        <img className="star-icons" alt="" src="/images/frame-2.svg" />
                                        <img className="star-icons" alt="" src="/images/frame-3.svg" />
                                        <img className="star-icons" alt="" src="/images/frame-4.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className="project-description">
                                <h1 className="projects-with-human-container">
                                    <span className="projects">Projects</span>
                                    {` with human & planet centric in mind`}
                                </h1>
                                <div className="project-subtitle">
                                    Check out some of our recent interior design projects that
                                    showcase our expertise and creativity. In this quarter we worked
                                    on a wide range of projects in different areas. Have a look below.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container">
                        <div className="quote-buttons">
                            {categories.map((category) => (
                                <button
                                    key={category}
                                    className={`button4 ${selectedCategory === category ? "active" : ""}`}
                                    onClick={() => setSelectedCategory(category)}
                                >
                                    {category}
                                    <img className="icon-relume17" alt="" src="/images/icon--relume.svg" />
                                </button>
                            ))}
                        </div>
                        <div className="project-selection">
                            {displayedProjects.length > 0 ? (
                                displayedProjects.map((project, index) => (
                                    <Slide
                                        key={index}
                                        slideNumberLabels={project.slideNumberLabels}
                                        iconslogos={project.iconslogos}
                                        hitchHikeProject={project.projectName}
                                        description={project.description}
                                        ctaText="View Project"
                                        iconArrow="/images/icon--arrow-1.svg"
                                        showIconCtaLeadIcon
                                        propBackgroundImage={`url(${project.background})`}
                                        propColor="#fff"
                                        className="relative inset-0 bg-black bg-opacity-60"
                                        onClick={() => viewProjectDetails(project)} // Pass project to view
                                    />
                                ))
                            ) : (
                                <div>No projects available for this category</div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

ProjectHero.propTypes = {
    className: PropTypes.string,
};

export default ProjectHero;
