import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const PurchaseOrderInvoice = ({ orderInvoice, close }) => {
    const [date, setDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString();
        setDate(formattedDate);
    }, []);

    return (
        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg print:border print:border-gray-300">
            {/* Header: Company Name and Close Button */}
            <div className="bg-gradient-to-r from-primaryDark to-secondaryDark text-white p-4 rounded-t-md flex justify-between items-center">
                <div>
                    <h1 className="text-2xl font-bold text-uppercase text-center text-blue-400 ">Kala Interiors</h1>
                    <p className="text-sm text-center">Office no. 1, Ground Floor, Near Madgiri Garden, Indrayani Nagar, Bhosari, Pune, Maharashtra 411026</p>
                </div>
                <button className="cursor-pointer text-white" onClick={() => close()}>
                    <X />
                </button>
            </div>

            {/* Main Content */}
            <div className="bg-white p-6 rounded-b-md">
                {/* Date and Supplier Info */}
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <label className="text-gray-700 font-medium">Date</label>
                        <input
                            type="text"
                            value={date}
                            readOnly
                            className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md px-3 py-2"
                        />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium">Supplier</label>
                        <input
                            type="text"
                            value={orderInvoice.supplier?.name || 'N/A'}
                            readOnly
                            className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md px-3 py-2"
                        />
                    </div>
                </div>

                {/* Product Table */}
                <table className="w-full mb-6 text-left">
                    <thead className="bg-gray-200 text-gray-700">
                        <tr>
                            <th className="py-2 px-3">#</th>
                            <th className="py-2 px-3">Item Purchase</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderInvoice.products.map((product, index) => (
                            <tr key={product.id} className="border-t">
                                <td className="py-3 px-3">{index + 1}</td>
                                <td className="py-3 px-3">{product.supplierProduct}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pricing Details */}
                <div className="mb-6">
                    <div className="flex justify-between text-gray-700 mb-2">
                        <p>Quantity:</p>
                        <span>{orderInvoice.quantityOrdered}</span>
                    </div>
                    <div className="flex justify-between text-gray-700 mb-2">
                        <p>Unit Price:</p>
                        <span>{orderInvoice.unitPrice}</span>
                    </div>
                    <div className="flex justify-between text-gray-700 border-t pt-2 mb-2">
                        <p>Total:</p>
                        <span>{orderInvoice.totalPrice}</span>
                    </div>
                    <div className="flex justify-between text-gray-700 border-t pt-2 mb-2">
                        <p>Tax:</p>
                        <span>0</span>
                    </div>
                    <div className="flex justify-between text-gray-900 font-bold border-t pt-2">
                        <p>Grand Total:</p>
                        <span>{orderInvoice.totalPrice}</span>
                    </div>
                </div>

                {/* Print Button */}
                <div className="text-right">
                    <button
                        className="bg-primaryDark text-white px-6 py-2 rounded-md shadow-md hover:bg-secondaryDark transition duration-150"
                        onClick={() => window.print()}
                    >
                        Print Invoice
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PurchaseOrderInvoice;
