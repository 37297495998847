import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axiosInstance from '../../services/AxiosInstance';

const ContactHistoryTable = () => {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(true);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newContact, setNewContact] = useState({
    customerId: '',
    contactDate: '',
    contactMethod: '',
    notes: '',
    nextFollowUpDate: '',
  });

  // State for customers and contact methods
  const [customers, setCustomers] = useState([]);
  const contactMethods = [
    { value: 'Phone', label: 'Phone' },
    { value: 'Email', label: 'Email' },
    { value: 'In-person', label: 'In-person' }
  ];

  // Fetch contacts and customers from API
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axiosInstance.get('/backend/api/contact/customer');
        // console.log(response.data);

        // Extract contact histories from each customer
        const contactsData = response.data.flatMap(customer =>
          customer.contactHistories.map(history => ({
            ...history,
            customerName: customer.name,
            customerId: customer.id,
            customerEmail: customer.email,
            customerPhone: customer.phone
          }))
        );

        setContacts(contactsData);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCustomers = async () => {
      try {
        const response = await axiosInstance.get('/backend/api/contact/customer');
        setCustomers(response.data.map(customer => ({ value: customer.id, label: customer.name })));
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };
    fetchContacts();
    fetchCustomers();
  }, []);

  // Handle sorting
  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);

    const sortedContacts = [...contacts].sort((a, b) => {
      if (order === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    setContacts(sortedContacts);
  };

  // Handle search
  const filteredContacts = contacts.filter((contact) =>
    // contact.contactMethod.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.notes.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.nextFollowUpDate.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle new contact submission
  const handleNewContactChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prev) => ({ ...prev, [name]: value }));
  };

  const handleContactMethodChange = (selectedOption) => {
    setNewContact((prev) => ({ ...prev, contactMethod: selectedOption.value }));
  };

  const handleCustomerChange = (selectedOption) => {
    setNewContact((prev) => ({ ...prev, customerId: selectedOption.value }));
  };

  const handleAddContact = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(`/api/contact-history/${newContact.customerId}`, {
        contactDate: newContact.contactDate,
        contactMethod: newContact.contactMethod,
        notes: newContact.notes,
        nextFollowUpDate: newContact.nextFollowUpDate
      });

      // Find the customer details from the `customers` state
      const customerDetails = customers.find(customer => customer.value === newContact.customerId);

      // Create a new contact entry with customer details
      const newContactEntry = {
        ...response.data,
        customerName: customerDetails.label, // Get customer name
        customerId: newContact.customerId,    // Store customer ID
        customerEmail: customerDetails.email, // Add customer email if needed
        customerPhone: customerDetails.phone  // Add customer phone if needed
      };

      // Add the new contact to the table
      setContacts(prevContacts => [...prevContacts, newContactEntry]);

      // Clear the form and close the modal
      setNewContact({
        customerId: '',
        contactDate: '',
        contactMethod: '',
        notes: '',
        nextFollowUpDate: ''
      });

      setIsModalOpen(false);

    } catch (error) {
      console.error('Error adding contact:', error);
    } finally {
      setFormLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="p-6 w-full bg-gray-50 mt-2 dark:bg-secondaryDark shadow-md rounded-lg">
      <h2 className="text-xl font-semibold mb-4 dark:text-dark">Contact History Table</h2>

      {/* Search Input */}
      <div className="mb-4 flex flex-col md:flex-row items-center ">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search contacts by method, notes, or follow-up date"
          className="p-2 border border-gray-300 rounded-md w-full md:w-3/4 md:mr-2"
        />

        {/* Button to open the modal */}
        <button
          onClick={() => setIsModalOpen(true)}
          className=" md:mb-0 px-4 py-2 text-nowrap bg-blue-500 text-white rounded hover:bg-blue-600 dark:bg-dark dark:hover:bg-primaryDark"
        >
          Add Follow-Up
        </button>
      </div>


      {/* Modal for adding a new contact */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-gray-50 p-6 rounded shadow-lg w-96 dark:bg-secondaryDark dark:text-dark">
            <h2 className="text-xl font-semibold mb-4">Add New Contact follow-Up</h2>
            <form onSubmit={handleAddContact}>

              {/* Customer Selection */}
              <div className="mb-2">
                <label htmlFor="customer" className="block text-gray-700 font-medium mb-1 dark:text-dark">
                  Select Customer
                </label>
                <Select
                  id="customer"
                  options={customers}
                  onChange={handleCustomerChange}
                  placeholder="Select Customer"
                  isSearchable
                  menuPlacement="auto"
                  maxMenuHeight={150}
                  required
                />
              </div>

              {/* Contact Date */}
              <div className="mb-2">
                <label htmlFor="contactDate" className="block text-gray-700 font-medium mb-1 dark:text-dark">
                  Contact Date
                </label>
                <input
                  id="contactDate"
                  type="date"
                  name="contactDate"
                  value={newContact.contactDate}
                  onChange={handleNewContactChange}
                  className="p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              {/* Contact Method */}
              <div className="mb-2">
                <label htmlFor="contactMethod" className="block text-gray-700 font-medium mb-1 dark:text-dark">
                  Contact Method
                </label>
                <Select
                  id="contactMethod"
                  options={contactMethods}
                  onChange={handleContactMethodChange}
                  placeholder="Select Contact Method"
                  required
                />
              </div>

              {/* Notes/Details */}
              <div className="mb-2">
                <label htmlFor="notes" className="block text-gray-700 font-medium mb-1 dark:text-dark">
                  Notes/Details
                </label>
                <textarea
                  id="notes"
                  name="notes"
                  value={newContact.notes}
                  onChange={handleNewContactChange}
                  placeholder="Notes/Details"
                  className="p-2 border border-gray-300 rounded-md w-full"
                ></textarea>
              </div>

              {/* Next Follow-Up Date */}
              <div className="mb-4">
                <label htmlFor="nextFollowUpDate" className="block text-gray-700 font-medium mb-1 dark:text-dark">
                  Next Follow-Up Date
                </label>
                <input
                  id="nextFollowUpDate"
                  type="date"
                  name="nextFollowUpDate"
                  value={newContact.nextFollowUpDate}
                  onChange={handleNewContactChange}
                  className="p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>

              {/* Action Buttons */}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600`}
                >
                  {formLoading ? "Adding Follow-Up" : "Add Follow-Up"}
                  {/* Add Contact */}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}


      <div className="overflow-x-auto">
        {/* Table */}
        <table className="min-w-full table-auto bg-gray-50 rounded-lg shadow-md ">
          <thead>
            <tr className="bg-gray-100 text-left text-nowrap select-none dark:bg-primaryDark dark:text-gray-50">
              <th className="px-4 py-2 cursor-pointer">#</th>
              <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('customerName')}>
                Customer Name {sortField === 'customerName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('contactDate')}>
                Contact Date {sortField === 'contactDate' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('contactMethod')}>
                Contact Method {sortField === 'contactMethod' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
              <th className="px-4 py-2">Notes</th>
              <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('nextFollowUpDate')}>
                Next Follow-up {sortField === 'nextFollowUpDate' && (sortOrder === 'asc' ? '↑' : '↓')}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
                    <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                    <p className='dark:text-secondaryDark' >Loading ...</p>
                  </div>
                </td>
              </tr>
            ) : filteredContacts.length > 0 ? (
              filteredContacts.map((contact, index) => (
                <tr key={contact.id} className="border-b text-nowrap dark:bg-secondaryDark dark:text-dark">
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="px-4 py-2">{contact.customerName}</td>
                  <td className="px-4 py-2">{contact.contactDate}</td>
                  <td className="px-4 py-2">{contact.contactMethod}</td>
                  <td className="px-4 py-2">{contact.notes}</td>
                  <td className="px-4 py-2">{contact.nextFollowUpDate}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4 dark:text-dark dark:bg-secondaryDark">No contact histories found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactHistoryTable;
