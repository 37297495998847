import React from 'react'
import RecordAlert from './RecordAlert'

const RecordAlertLayout = () => {
    return (
        <div className="flex justify-center items-start h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl">
                {/* Left side - Sales Pipeline Component */}

                {/* Right side - Kanban Board and Deal Table */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                    {/* <DealsKanbanBoard /> */}
                    {/* <DealTable /> */}
                    <RecordAlert />
                </div>
            </div>
        </div>
    )
}

export default RecordAlertLayout