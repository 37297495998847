import React from 'react'
import { FiAlertTriangle, FiCheckCircle, FiXCircle } from 'react-icons/fi';


const LowStockTable = () => {


    // Mock data for low-stock products
    const lowStockProducts = [
        { id: 1, name: 'Widget A', currentStock: 5, reorderPoint: 10, status: 'critical' },
        { id: 2, name: 'Gadget B', currentStock: 8, reorderPoint: 15, status: 'warning' },
        { id: 3, name: 'Tool C', currentStock: 12, reorderPoint: 20, status: 'normal' },
        { id: 4, name: 'Part D', currentStock: 3, reorderPoint: 10, status: 'critical' },
        { id: 5, name: 'Component E', currentStock: 7, reorderPoint: 12, status: 'warning' },
    ];

    const StatusIcon = ({ status }) => {
        switch (status) {
            case 'critical':
                return <FiXCircle className="w-6 h-6 text-red-500" />;
            case 'warning':
                return <FiAlertTriangle className="w-6 h-6 text-yellow-500" />;
            case 'normal':
                return <FiCheckCircle className="w-6 h-6 text-green-500" />;
            default:
                return null;
        }
    };


    return (
        <div className="overflow-x-auto bg-white shadow-md rounded-lg">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Product
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Current Stock
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Reorder Point
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {lowStockProducts.map((product) => (
                        <tr key={product.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{product.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.currentStock}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.reorderPoint}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                    <StatusIcon status={product.status} />
                                    <span className="ml-2 text-sm text-gray-500 capitalize">{product.status}</span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default LowStockTable