import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { EyeIcon } from 'lucide-react';
import PurchaseOrderInvoice from './PurchaseOrderInvoice';

const PurchaseOrderTable = ({ purchaseOrder }) => {
    const [purchaseOrders, setPurchaseOrders] = useState([]); // Initially empty
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showInvoice, setShowInvoice] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState([]);

    useEffect(() => {
        const fetchPurchaseOrders = async () => {
            setLoading(true);
            setErrorMessage('');

            try {
                const response = await axiosInstance.get('/backend/api/purchase-orders');
                console.log(response.data);

                setPurchaseOrders(response.data);
            } catch (error) {
                console.error('Error fetching purchase orders:', error);
                setErrorMessage('Failed to fetch purchase orders.');
            } finally {
                setLoading(false);
            }
        };

        fetchPurchaseOrders();
    }, []);

    useEffect(() => {
        if (purchaseOrder && purchaseOrder.length > 0) {
            setPurchaseOrders((prev) => [...prev, ...purchaseOrder]);
        }
    }, [purchaseOrder]);

    const statusBadge = (status) => {
        switch (status) {
            case 'Pending':
                return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200">Pending</span>;
            case 'Delivered':
                return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">Delivered</span>;
            default:
                return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200">{status}</span>;
        }
    };

    const showPurchaseInvoice = (order) => {
        setSelectedOrder(order);
        setShowInvoice(true);
    };

    const onClose = () => {
        setSelectedOrder('');
        setShowInvoice(false);
    };

    return (
        <div className="bg-gray-50 p-3 rounded-lg shadow-lg max-w-6xl mx-auto  w-full dark:bg-secondaryDark">
            <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">Purchase Orders</h2>

            {loading && <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
                <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                <p className='dark:text-secondaryDark' >Loading purchase orders</p>
            </div>}
            {errorMessage && <div className="p-2 bg-red-100 text-red-800 rounded-md mb-4">{errorMessage}</div>}

            <div className="overflow-y-auto">
                {!loading && purchaseOrders.length > 0 ? (
                    <table className="table-auto w-full border-collapse text-sm">
                        <thead>
                            <tr className="bg-gray-100 dark:bg-primaryDark dark:text-gray-50 text-gray-600">
                                <th className="p-4 font-medium text-sm text-nowrap ">Order ID</th>
                                <th className="p-4 font-medium text-sm text-nowrap ">Supplier Name</th>
                                <th className="p-4 font-medium text-sm text-nowrap ">Order Date</th>
                                <th className="p-4 font-medium text-sm text-nowrap ">Status</th>
                                <th className="p-4 font-medium text-sm text-nowrap ">Products</th>
                                <th className="p-4 font-medium text-sm text-nowrap ">Total Price</th>
                                <th className="p-4 font-medium text-sm text-nowrap ">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-sm text-nowrap">
                            {purchaseOrders.map((order, index) => (
                                <tr key={order.id} className={`${index % 2 === 0 ? 'dark:bg-secondaryDark' : 'dark:bg-secondaryDark'} hover:bg-gray-100 dark:hover:bg-gray-500 font-semibold text-gray-800 dark:text-dark transition duration-200 border-t`}>
                                    <td className="p-4  text-center">{order.id}</td>
                                    <td className="p-4 ">{order.supplier?.name}</td>
                                    <td className="p-4 ">{order.orderDate}</td>
                                    <td className="p-4 text-center">{statusBadge(order.status)}</td>
                                    <td className="p-4 ">
                                        <ul className="list-decimal pl-5">
                                            {order.purchaseOrderProducts?.map((product) => (
                                                <li key={product.id} className="mb-2">
                                                    <span className="font-medium">Product:</span> {product.product?.supplierProduct}
                                                    <br />
                                                    <span className="font-medium">Quantity:</span> {product.quantity}
                                                    <br />
                                                    <span className="font-medium">Unit Price:</span> ₹{product.unitPrice}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className="p-4 text-gray-800">₹{order.totalPrice}</td>
                                    <td className="p-4 text-center">
                                        <button onClick={() => showPurchaseInvoice(order)}>
                                            <EyeIcon />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    !loading && <div className="text-center text-gray-600">No purchase orders available.</div>
                )}
            </div>

            {showInvoice && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <PurchaseOrderInvoice orderInvoice={selectedOrder} close={onClose} />
                </div>
            )}
        </div>
    );
};

export default PurchaseOrderTable;
