import React, { useEffect, useState } from 'react';
import { ProjectOverview } from './ProjectOverview';
import { InvoiceHistory } from './InvoiceHistory';
import { ClientFeedback } from './ClientFeedback';
import axiosInstance from '../../services/AxiosInstance';

export default function ClientPortal() {
  const [activeTab, setActiveTab] = useState('projects');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [customer, setCustomer] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [customerName, setCustomerName] = useState("");

  const customerId = localStorage.getItem('customerId');

  useEffect(() => {
    const fetchCustomer = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`backend/api/contact/customer/${customerId}`);
        if (response.status === 200) {

          // console.log(response.data)
          const customerData = response.data;

          // Extract customer name
          setCustomerName(customerData.name || "Customer");

          // Filter leads with projects
          const leadsWithProjects = customerData.leads
            .filter(lead => lead.project)
            .map(lead => ({
              id: lead.project.id,
              // customerId: lead.id,
              name: lead.project.name,
              description: lead.project.description || 'No description available.',
              startDate: lead.project.startDate,
              endDate: lead.project.endDate,
              status: lead.project.status,
              tasks: lead.project.tasks.map(task => ({
                task: task.title,
                status: task.status,
              })),
              planning: lead.project.milestones.map(milestone => ({
                description: milestone.description,
                startDate: milestone.date,
                endDate: milestone.endDate
              })),
            }));

          setInvoice(customerData.invoices);
          setCustomer(leadsWithProjects);
        } else {
          setError("Failed to fetch customer");
        }
      } catch (error) {
        setError("Failed to fetch customer");
        console.error("Error fetching customer:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    // Redirect to login page or appropriate route
    window.location.href = '/login';
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">Client Portal</h1>
          <div className="flex items-center">
            <span className="mr-4 text-lg font-semibold">{customerName}</span>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </header>
      <nav className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex">
            {['Projects', 'Invoices', 'Feedback'].map((tab) => (
              <button
                key={tab}
                className={`px-3 py-2 text-sm font-medium ${activeTab === tab.toLowerCase()
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
                  }`}
                onClick={() => setActiveTab(tab.toLowerCase())}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </nav>
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 relative">
        {loading && (
          <div className="absolute top-0 left-0 right-0 flex justify-center items-center gap-3 bg-white bg-opacity-75 p-4 z-50">
            <div className="animate-spin rounded-full border-t-4 border-blue-600 w-16 h-16"></div>
            <p>Loading...</p>
          </div>
        )}

        {error && <p className="text-red-500">{error}</p>}

        {activeTab === 'projects' && <ProjectOverview projects={customer} />}
        {activeTab === 'invoices' && <InvoiceHistory invoices={invoice} />}
        {activeTab === 'feedback' && <ClientFeedback projects={customer} loading={loading} customerId={customerId} />}
      </main>

    </div>
  );
}
