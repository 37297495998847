import React, { useState } from 'react';
import { Star } from 'lucide-react';

const PerformanceReview = () => {
  const [selectedMetric, setSelectedMetric] = useState('');
  const [reviewPeriod, setReviewPeriod] = useState('');
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [goalsAchieved, setGoalsAchieved] = useState('');
  const [formError, setFormError] = useState('');

  const handleReviewSubmit = ({ selectedMetric, reviewPeriod, rating, feedback, goalsAchieved }) => {
    // Perform any form submission logic here, like sending data to an API.
    console.log('Form Submitted:', { selectedMetric, reviewPeriod, rating, feedback, goalsAchieved });
    // You can reset the form fields after submission if needed
    setSelectedMetric('');
    setReviewPeriod('');
    setRating(0);
    setFeedback('');
    setGoalsAchieved('');
    setFormError(''); // Clear error if any
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!selectedMetric || !reviewPeriod || !rating || !feedback || !goalsAchieved) {
      setFormError('All fields are required');
      return;
    }

    handleReviewSubmit({ selectedMetric, reviewPeriod, rating, feedback, goalsAchieved });
  };

  return (
    <div className="bg-gray-50 dark:bg-secondaryDark shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4 dark:text-dark">Performance Review</h2>
      <form onSubmit={handleSubmit} className="space-y-4 dark:text-dark text-gray-700">
        {formError && <div className="text-red-500 text-sm">{formError}</div>}
        <div>
          <label htmlFor="performanceMetric" className="block mb-2 text-sm font-medium ">
            Performance Metric
          </label>
          <select
            id="performanceMetric"
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
            className="block w-full p-2 border border-gray rounded-md bg-gray-50"
          >
            <option value="">Select Metric</option>
            <option value="Punctuality">Punctuality</option>
            <option value="TeamCollaboration">Team Collaboration</option>
            <option value="ProductivityScore">Productivity Score</option>
          </select>
        </div>
        <div>
          <label htmlFor="reviewPeriod" className="block mb-2 text-sm font-medium ">
            Review Period
          </label>
          <select
            id="reviewPeriod"
            value={reviewPeriod}
            onChange={(e) => setReviewPeriod(e.target.value)}
            className="block w-full p-2 border border-gray rounded-md bg-white"
          >
            <option value="">Select Period</option>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
          </select>
        </div>
        <div>
          <label htmlFor="rating" className="block mb-2 text-sm font-medium ">
            Rating
          </label>
          <div className="flex items-center mt-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                className={`h-6 w-6 cursor-pointer ${star <= rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`}
                onClick={() => setRating(star)}
              />
            ))}
          </div>
        </div>
        <div>
          <label htmlFor="feedback" className="block mb-2 text-sm font-medium ">
            Feedback
          </label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows={3}
            className="block w-full p-2 border border-gray rounded-md bg-white"
          ></textarea>
        </div>
        <div>
          <label htmlFor="goalsAchieved" className="block mb-2 text-sm font-medium ">
            Goals Achieved
          </label>
          <textarea
            id="goalsAchieved"
            value={goalsAchieved}
            onChange={(e) => setGoalsAchieved(e.target.value)}
            rows={3}
            className="block w-full p-2 border border-gray rounded-md bg-white"
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-dark dark:hover:bg-primaryDark"
        >
          Submit Review
        </button>
      </form>
    </div>
  );
};

export default PerformanceReview;
