import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

const AdminLeaveStatus = () => {
    const [leaves, setLeaves] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
    const [filteredLeaves, setFilteredLeaves] = useState([]);
    const [showAllLeaves, setShowAllLeaves] = useState(false);
    const [showUpcomingLeaves, setShowUpcomingLeaves] = useState(false);

    useEffect(() => {
        const fetchLeaves = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`backend/api/leaves`);
                if (response.status === 200) {
                    setLeaves(response.data);
                } else {
                    setError("Failed to fetch data");
                }
            } catch (error) {
                setError("Failed to fetch data");
            } finally {
                setLoading(false);
            }
        };
        fetchLeaves();
    }, []);

    useEffect(() => {
        if (showAllLeaves) {
            setFilteredLeaves(leaves);
        } else if (showUpcomingLeaves) {
            const today = new Date(selectedDate);
            const filtered = leaves.filter(leave => new Date(leave.startDate) > today);
            setFilteredLeaves(filtered);
        } else {
            const today = new Date(selectedDate);
            const filtered = leaves.filter(leave => {
                const leaveStartDate = new Date(leave.startDate);
                const leaveEndDate = new Date(leave.endDate);
                return today <= leaveEndDate && today >= leaveStartDate;
            });
            setFilteredLeaves(filtered);
        }
    }, [leaves, selectedDate, showAllLeaves, showUpcomingLeaves]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        setShowAllLeaves(false);
        setShowUpcomingLeaves(false);
    };

    const handleShowAllLeaves = () => {
        setShowAllLeaves(!showAllLeaves);
        setShowUpcomingLeaves(false);
    };

    const handleShowUpcomingLeaves = () => {
        setShowUpcomingLeaves(!showUpcomingLeaves);
        setShowAllLeaves(false);
    };

    const handleEditStatus = async (leaveId, newStatus) => {
        try {
            const response = await axiosInstance.put(`backend/api/leaves/${leaveId}`, { status: newStatus });
            if (response.status === 200) {
                setLeaves(leaves.map(leave => leave.leaveId === leaveId ? { ...leave, status: newStatus } : leave));
            } else {
                setError("Failed to update leave status");
            }
        } catch (error) {
            setError("Failed to update leave status");
        }
    };

    return (
        <div className="container mx-auto p-2 space-y-4  rounded-lg shadow-md  ">
            <div className="flex flex-col sm:flex-row sm:justify-between items-center bg-gray-50 p-4 rounded-lg shadow mb-2 dark:bg-secondaryDark space-y-4 sm:space-y-0">
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-gray-50">Leave Status Management</h1>

                <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
                    <label htmlFor="leaveDate" className="text-lg dark:text-gray-200">Select Date:</label>
                    <input
                        type="date"
                        id="leaveDate"
                        value={selectedDate}
                        onChange={handleDateChange}
                        className="border border-gray-300 rounded p-2 dark:bg-dark dark:text-gray-100 w-full sm:w-auto"
                        disabled={showAllLeaves || showUpcomingLeaves}
                    />
                    <button
                        onClick={handleShowAllLeaves}
                        className={`p-2 rounded w-full sm:w-auto ${showAllLeaves ? 'bg-red-600 text-white' : 'bg-blue-600 text-white'}`}
                    >
                        {showAllLeaves ? 'Undo Show All' : 'Show All Leaves'}
                    </button>
                    <button
                        onClick={handleShowUpcomingLeaves}
                        className={`p-2 rounded w-full sm:w-auto ${showUpcomingLeaves ? 'bg-red-600 text-white' : 'bg-green-600 text-white'}`}
                    >
                        {showUpcomingLeaves ? 'Undo Upcoming Leaves' : 'Show Upcoming Leaves'}
                    </button>
                </div>
            </div>


            {loading ? (
                <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
                    <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
                    <p className='dark:text-secondaryDark' >Loading leaves..</p>
                </div>
            ) : error ? (
                <p className="text-center text-red-600">{error}</p>
            ) : (
                <div className="dark:bg-secondaryDark p-4 rounded-lg shadow">
                    <h2 className="text-2xl font-semibold mb-4 dark:to-dark">
                        {showAllLeaves ? "All Leaves" : showUpcomingLeaves ? "Upcoming Leaves" : `Leaves for ${selectedDate}`}
                    </h2>

                    {filteredLeaves.length === 0 ? (
                        <p className="text-center text-gray-600">No leaves found for this view.</p>
                    ) : (
                        <table className="min-w-full ">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b">Employee ID</th>
                                    <th className="py-2 px-4 border-b text-nowrap">Full Name</th>
                                    <th className="py-2 px-4 border-b text-nowrap">Leave Type</th>
                                    <th className="py-2 px-4 border-b text-nowrap">From Date</th>
                                    <th className="py-2 px-4 border-b text-nowrap">To Date</th>
                                    <th className="py-2 px-4 border-b text-nowrap">Status</th>
                                    <th className="py-2 px-4 border-b text-nowrap">Leave Reason</th>
                                    <th className="py-2 px-4 border-b">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredLeaves.map((leave) => (
                                    <tr key={leave.id} className="text-center">
                                        <td className="py-2 px-4 border-b">{leave.employee.employeeId}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">{leave.employee.fullName}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">{leave.leaveType}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">{new Date(leave.startDate).toLocaleDateString()}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">{new Date(leave.endDate).toLocaleDateString()}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">{leave.status}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">{leave.leaveReason}</td>
                                        <td className="py-2 px-4 border-b text-nowrap">
                                            <select
                                                value={leave.status}
                                                onChange={(e) => handleEditStatus(leave.leaveId, e.target.value)}
                                                className="border border-gray-300 rounded p-1"
                                            >
                                                <option value="Approved">Approved</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Rejected">Rejected</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdminLeaveStatus;
