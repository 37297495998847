import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ShowProject = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [isMilestonesModalOpen, setIsMilestonesModalOpen] = useState(false);
  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    axiosInstance.get('/backend/projects')
      .then(response => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch project data');
        setLoading(false);
      });
  }, []);

  const handleShowMilestones = (milestones) => {
    setSelectedMilestones(milestones);
    setIsMilestonesModalOpen(true);
  };

  const handleShowBudget = (budget) => {
    setSelectedBudget(budget);
    setIsBudgetModalOpen(true);
  };

  const closeMilestonesModal = () => {
    setIsMilestonesModalOpen(false);
    setSelectedMilestones([]);
  };

  const closeBudgetModal = () => {
    setIsBudgetModalOpen(false);
    setSelectedBudget(null);
  };

  const handleView = (projectId) => {
    console.log(`View project with ID: ${projectId}`);
  };

  const handleUpdate = (projectId) => {
    console.log(`Update project with ID: ${projectId}`);
  };

  const handleDelete = (projectId) => {
    console.log(`Delete project with ID: ${projectId}`);
  };

  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const paginatedProjects = projects.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
        <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
        <p className='dark:text-secondaryDark' >Loading projects</p>
      </div>
      // <div className="text-center h-[calc(100vh-3.5rem)]">Loading...</div>;
    )
  }

  if (error) {
    return <div className="text-center mt-16 h-[calc(100vh-3.5rem)] text-red-600">{error}</div>;
  }

  return (
    <div className="p-2  h-[calc(100vh-3.5rem)] ">
      <div className="dark:bg-secondaryDark dark:text-dark p-4 rounded-md mb-2 shadow-lg">
        <h1 className="text-2xl font-semibold text-center "> All Projects</h1>
      </div>
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-[1000px] bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal dark:bg-primaryDark dark:text-textDark">
              <th className="py-3 px-6 text-left">#</th>
              <th className="py-3 px-6 text-left">Project Name</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-left">Leader</th>
              <th className="py-3 px-6 text-left">Start Date</th>
              <th className="py-3 px-6 text-left">End Date</th>
              <th className="py-3 px-6 text-left">Milestones</th>
              <th className="py-3 px-6 text-left">Budget</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm dark:bg-secondaryDark dark:text-dark font-semibold ">
            {projects.map((project, index) => (
              <tr key={project.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6">{index + 1}</td>
                <td className="py-3 px-6">{project.name}</td>
                <td className="py-3 px-6">{project.description}</td>
                <td className="py-3 px-6">{project.employee?.fullName}</td>
                <td className="py-3 px-6">{project.startDate}</td>
                <td className="py-3 px-6">{project.endDate}</td>
                <td className="py-3 px-6">
                  <button onClick={() => handleShowMilestones(project.milestones)} className="text-blue-500 hover:underline">
                    Show Milestones
                  </button>
                </td>
                <td className="py-3 px-6">
                  <button onClick={() => handleShowBudget(project.budget)} className="text-blue-500 hover:underline">
                    Show Budget
                  </button>
                </td>
                <td className="py-3 px-6 text-center">
                  <button onClick={() => handleView(project.id)} className="text-green-600 mx-2">
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                  <button onClick={() => handleUpdate(project.id)} className="text-blue-600 mx-2">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleDelete(project.id)} className="text-red-600 mx-2">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="bg-blue-600 text-white py-1 px-3 rounded-lg disabled:opacity-50"
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="bg-blue-600 text-white py-1 px-3 rounded-lg disabled:opacity-50"
        >
          Next
        </button>
      </div>
      
      {/* Modals for Milestones and Budget (existing code) */}
      {isMilestonesModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Project Milestones</h2>
            <div className="max-h-64 overflow-y-auto">
              {selectedMilestones.length > 0 ? (
                selectedMilestones.map((milestone) => (
                  <div key={milestone.id} className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
                    <p><strong>Description:</strong> {milestone.description}</p>
                    <p><strong>Status:</strong> <span className={`${milestone.status === 'Completed' ? 'text-green-600' : 'text-yellow-600'}`}>{milestone.status}</span></p>
                    <p><strong>Completion:</strong> <span className="font-bold">{milestone.completionPercentage}%</span></p>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500">No milestones available</p>
              )}
            </div>
            <button onClick={closeMilestonesModal} className="mt-6 w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Close</button>
          </div>
        </div>
      )}

      {isBudgetModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Budget Overview</h2>
            {selectedBudget ? (
              <>
                <p><strong>Total Budget:</strong> <span className="text-green-600">${selectedBudget.total}</span></p>
                <p><strong>Amount Spent:</strong> <span className="text-red-600">${selectedBudget.spent}</span></p>
                <p><strong>Remaining:</strong> <span className="text-blue-600">${selectedBudget.remaining}</span></p>
              </>
            ) : (
              <p className="text-center text-gray-500">No budget data available</p>
            )}
            <button onClick={closeBudgetModal} className="mt-6 w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowProject;
