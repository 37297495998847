import React, { useState } from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import axiosInstance from '../../services/AxiosInstance';

const AddManPowers = ({addManPower}) => {
    const [formData, setFormData] = useState({
        name: '',
        role: 'worker',
        availability: ''
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage({ type: '', text: '' });

        try {
            const response = await axiosInstance.post('/backend/api/user', formData);
            addManPower(response.data);
            setMessage({ type: 'success', text: 'User added successfully!' });
            setFormData({
                name: '',
                role: 'worker',
                availability: ''
            });
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || 'Failed to add user. Please try again.';
            setMessage({ type: 'error', text: errorMessage });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-lg mx-auto  bg-gray-50 dark:bg-secondaryDark p-8 rounded-lg shadow-lg">
            {/* Section Title */}
            <div className="flex items-center mb-6">
                <FaUserPlus className="text-blue-500 text-3xl mr-3" />
                <h2 className="text-2xl font-bold text-gray-800 dark:text-dark">
                    Add Manpower
                </h2>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6 dark:text-dark">
                {/* Name Field */}
                <div>
                    <label className="block text-lg font-medium text-gray-700 dark:text-dark" htmlFor="name">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300 "
                        placeholder="Enter name"
                        required
                    />
                </div>

                {/* Role Dropdown */}
                <div>
                    <label className="block text-lg font-medium text-gray-700 dark:text-dark" htmlFor="role">
                        Role
                    </label>
                    <select
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300 "
                        required
                    >
                        <option value="leader">Leader</option>
                        <option value="worker">Worker</option>
                    </select>
                </div>

                {/* Availability Field */}
                <div>
                    <label className="block text-lg font-medium text-gray-700 dark:text-dark" htmlFor="availability">
                        Availability (Hours per week)
                    </label>
                    <input
                        type="number"
                        id="availability"
                        name="availability"
                        value={formData.availability}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300  "
                        placeholder="Enter availability"
                        min="0"
                        required
                    />
                </div>

                {/* Message */}
                {message.text && (
                    <div
                        className={`p-3 rounded-lg text-center ${message.type === 'success'
                            ? 'bg-green-200 text-green-800'
                            : 'bg-red-200 text-red-800'
                            }`}
                    >
                        {message.text}
                    </div>
                )}

                {/* Submit Button */}
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className={`px-4  py-3 text-sm text-nowrap font-semibold rounded-lg shadow-md text-white transition-transform transform-gpu ${loading
                            ? 'bg-gray-500 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-700'
                            } focus:outline-none focus:ring focus:ring-blue-300 flex items-center`}
                        disabled={loading}
                    >
                        {loading ? (
                            <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                        ) : (
                            'Add Manpower'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddManPowers;
