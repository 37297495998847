import React from 'react';
import { FaFacebook, FaLinkedin, FaYoutube, FaWhatsapp, FaTwitter } from 'react-icons/fa';

export default function Component() {
  const socialLinks = {
    linkedin: 'https://www.linkedin.com/in/kala-interior-a3a088333?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/',
    youtube: 'https://www.youtube.com/',
    whatsapp1: 'https://wa.me/+918378989511',
    whatsapp2: 'https://wa.me/+918378989507',
  };

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-slate-400">
      <h1 className="text-4xl font-bold text-center mb-4 text-green-800">Meet Our Creative Directors</h1>
      <h2 className="text-2xl font-semibold text-center mb-8 text-gray-900">Innovative & Futuristic</h2>

      <div className="relative max-w-4xl mx-auto">
        <div className="flex justify-center mb-8">
          <svg width="180" height="150" viewBox="0 0 171 129" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-32 h-24 lg:w-auto lg:h-auto transform rotate-180">
            <path
              d="M1 128.374C24.2997 92.523 82.0713 29.9692 133.574 58.3837C168.736 77.7835 134.651 108.257 97.1699 85.7929C59.6885 63.3289 81.793 0.175376 170.113 16.2371M170.113 16.2371L156.325 26.2176M170.113 16.2371L153.636 1.31576"
              stroke="url(#paint0_linear_69_3690)"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_69_3690"
                x1="23.4948"
                y1="-13"
                x2="142.518"
                y2="149.951"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.488534" stopColor="#192C4B" />
                <stop offset="1" stopColor="#0D193A" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-5 overflow-hidden transition-transform duration-300 hover:scale-105">

            <div className="flex-shrink-0 flex flex-col sm:flex-row justify-center">
                <img className="w-64 h-60 object-cover rounded-xl m-3" src="/amit-landge.jpg" alt="Amit Landge" />
                <img className="w-64 h-60 object-cover rounded-xl m-3" src="/hema-landge.jpg" alt="Hema Amit Landge" />
            </div>

            <div className="p-8">
                    <div className="uppercase tracking-wide text-center text-sm text-orange-700 font-semibold">Directors</div>
                    <h3 className="mt-1 text-3xl font-bold text-center leading-tight text-black">Amit Landge & Hema Amit Landge</h3>
                    <p className="mt-2 text-gray-500 p-2 text-justify">
                        Amit and Hema Landge are visionary leaders in the interior design industry. Together, they combine innovation and futuristic thinking to create breathtaking spaces that reflect both beauty and functionality.
                    </p>
            </div>

          <div className="px-8 py-4 bg-gray-50 ">
            <p className="text-gray-700 p-2 text-justify">
              With Amit's expertise in transforming spaces and Hema's keen eye for design trends, they lead Kala Interiors with a focus on creativity, sustainability, and client satisfaction. Their collaborative approach ensures that each project is not just a space, but a masterpiece that balances aesthetics with functionality.
            </p>
          </div>
          <div className="px-8 py-4 flex justify-center space-x-4 bg-gray-100">
            {Object.entries(socialLinks).map(([key, url]) => (
              <button key={key} onClick={() => openLink(url)} className="text-gray-600 hover:text-blue-500 transition-colors duration-300" aria-label={`${key} link`}>
                {key === 'linkedin' && <FaLinkedin size={24} />}
                {key === 'facebook' && <FaFacebook size={24} />}
                {key === 'twitter' && <FaTwitter size={24} />}
                {key === 'youtube' && <FaYoutube size={24} />}
                {key.startsWith('whatsapp') && <FaWhatsapp size={24} />}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}