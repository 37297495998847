import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';

const EquipmentAllocationTable = ({ equipment }) => {
    const [equipmentList, setEquipmentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [statusFilter, setStatusFilter] = useState('Assigned'); // New: Status filter (All, Assigned, Available)
    const [searchQuery, setSearchQuery] = useState(''); // New: Search by user

    // Fetch equipment data
    useEffect(() => {
        const fetchEquipment = async () => {
            try {
                const response = await axiosInstance.get('/backend/api/equipment');
                const equipmentData = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
                setEquipmentList(equipmentData);
            } catch (err) {
                setError('Error fetching equipment data');
            } finally {
                setLoading(false);
            }
        };

        fetchEquipment();
    }, []);


    useEffect(() => {
        if (equipment && Array.isArray(equipment)) {
            // If equipment is an array, concatenate it with the previous list
            setEquipmentList(prev => [...prev, ...equipment]);
        } else if (equipment) {
            // If equipment is a single item, add it to the previous list
            setEquipmentList(prev => [...prev, equipment]);
        }
    }, [equipment]);



    // Action to return equipment (update status)
    const handleReturnEquipment = (equipmentId) => {
        const submissionData = { status: "Available" };
        axiosInstance.put(`/backend/api/equipment/${equipmentId}/update`, submissionData)
            .then(() => {
                setEquipmentList(prevList =>
                    prevList.map(equip =>
                        equip.id === equipmentId ? { ...equip, status: 'Available' } : equip
                    )
                );
            })
            .catch(err => {
                console.error("Error returning equipment", err);
            });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Filter the equipment list based on the selected status filter and search query
    const filteredEquipmentList = equipmentList
        .filter(equipment => {
            // Status filter: Assigned, Available, or All
            if (statusFilter === 'Assigned' && equipment.status !== 'Assigned') return false;
            if (statusFilter === 'Available' && equipment.status !== 'Available') return false;
            return true;
        })
        .filter(equipment => {
            // Search filter: Match user names (assignedTo) with the search query
            if (searchQuery) {
                return equipment.assignedTo?.name.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return true;
        });

    return (
        <div className="overflow-x-auto">
            {/* Filter Controls */}
            <div className="flex justify-between items-center mb-4 bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow-md">
                {/* Status Filter Dropdown */}
                <div className="flex items-center space-x-2">
                    <label htmlFor="statusFilter" className="text-gray-700 dark:text-dark font-semibold">Filter by status:</label>
                    <div className="relative">
                        <select
                            id="statusFilter"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="appearance-none px-4 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 hover:shadow focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
                        >
                            <option value="All">All</option>
                            <option value="Assigned">Assigned</option>
                            <option value="Available">Available</option>
                        </select>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg className="h-5 w-5 text-gray-400 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </span>
                    </div>
                </div>

                {/* Search by User */}
                <div className="flex items-center space-x-2">
                    <label htmlFor="search" className="text-dark dark:text-gray-300 text-nowrap font-semibold">Search by user:</label>
                    <div className="relative w-full">
                        <input
                            type="text"
                            id="search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Enter user name"
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:text-white dark:border-gray-600 hover:shadow focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg className="h-5 w-5 text-gray-400 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 12h12M6 6h12M6 18h12"></path>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>


            {/* Equipment Table */}
            {filteredEquipmentList.length === 0 ? (
                <div className="text-center py-8 text-gray-500 dark:text-dark">
                    No equipment matches the selected filter.
                </div>
            ) : (
                <table className="min-w-full table-auto bg-gray-50 dark:bg-secondaryDark border  rounded-lg shadow-lg">
                    <thead className="bg-[#349330] text-white dark:bg-secondaryDark rounded-lg">
                        <tr>
                            <th className="py-3 px-6 text-left text-sm font-semibold">Equipment Name</th>
                            <th className="py-3 px-6 text-left text-sm font-semibold">Assigned To</th>
                            <th className="py-3 px-6 text-left text-sm font-semibold">Task</th>
                            <th className="py-3 px-6 text-left text-sm font-semibold">Status</th>
                            <th className="py-3 px-6 text-left text-sm font-semibold">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEquipmentList.map((equipment, index) => (
                            <tr key={equipment.id} className={`${index % 2 === 0 ? 'bg-gray-50 dark:bg-secondaryDark' : 'bg-gray-50 dark:bg-secondaryDark'} hover:bg-gray-100 text-gray-50 dark:text-dark dark:hover:bg-primaryDark transition duration-200`}>
                                <td className="py-3 px-6 border-b  text-sm">{equipment.equipmentName}</td>
                                <td className="py-3 px-6 border-b  text-sm">{equipment.assignedTo?.name || 'Unassigned'}</td>
                                <td className="py-3 px-6 border-b  text-sm">{equipment.task?.title || 'Unassigned'}</td>
                                <td className="py-3 px-6 border-b  text-sm">
                                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${equipment.status === 'Assigned' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                        {equipment.status}
                                    </span>
                                </td>
                                <td className="py-3 px-6 border-b  text-sm">
                                    {equipment.status === 'Assigned' ? (
                                        <button
                                            className="px-4 py-2 bg-blue-500 text-white text-sm font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200"
                                            onClick={() => handleReturnEquipment(equipment.id)}
                                        >
                                            Return
                                        </button>
                                    ) : (
                                        <span className="text-gray-500 dark:text-gray-400">No action</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default EquipmentAllocationTable;
