import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';
import { AiFillEye, AiFillEdit } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import { faHome } from 'react-icons/ai';

export default function ContractManagement() {
  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // Add state for search query



  const [vendor, setVendor] = useState(null); // Update vendor state to store the selected option
  const [vendors, setVendors] = useState([]); // Store list of vendors
  const [contractTerms, setContractTerms] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reminder, setReminder] = useState('');
  const [status, setStatus] = useState('Active');

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axiosInstance.get("/backend/api/vendor");
        const vendorOptions = response.data.map((vendor) => ({
          value: vendor.id,
          label: vendor.name
        }));

        setVendors(vendorOptions);
      } catch (err) {
        console.error("Failed to fetch vendors", err);
      }
    };

    const fetchContracts = async () => {
      try {
        const response = await axiosInstance.get("/backend/api/contract");
        setContracts(response.data);
      } catch (err) {
        console.error("Failed to fetch contracts", err);
      }
    };

    fetchVendors();
    fetchContracts();
  }, []);

  const handleVendorChange = (selectedOption) => {
    setVendor(selectedOption);
  };

  const handleViewContract = (contract) => {
    setSelectedContract(contract);
    setShowModal(true);
  };

  const handleEditContract = (contract) => {
    setSelectedContract(contract);
    // Set form fields with the contract's data
    setVendor({ value: contract.vendor.id, label: contract.vendor.name }); // Set vendor as an object with value and label
    setContractTerms(contract.contractTerm);
    setStartDate(contract.startDate);
    setEndDate(contract.endDate);
    setReminder(contract.renewRemainder);
    setStatus(contract.status);
    setShowForm(true);
  };

  const handleSaveContract = async (e) => {
    e.preventDefault();

    const submissionData = {
      contractTerm: contractTerms,
      startDate,
      endDate,
      renewRemainder: reminder,
      status
    };

    try {
      if (selectedContract) {
        // Update existing contract
        await axiosInstance.put(`/backend/api/contract/${selectedContract.id}`, submissionData);
        setContracts(contracts.map(contract =>
          contract.id === selectedContract.id ? { ...selectedContract, vendor, ...submissionData } : contract
        ));
        alert('Contract updated successfully');
      } else {
        // Create new contract
        const response = await axiosInstance.post(`/backend/api/contract/${vendor.value}`, submissionData);
        setContracts([...contracts, response.data]);
        alert('New contract created successfully');
      }
      setShowForm(false);
      setSelectedContract(null);
    } catch (error) {
      console.error('Error saving contract:', error);
      alert('Failed to save the contract');
    }
  };

  // Filter contracts based on search query
  const filteredContracts = contracts.filter(contract => {
    const vendorName = contract.vendor?.name?.toLowerCase() || '';
    const status = contract.status?.toLowerCase() || '';
    return vendorName.includes(searchQuery.toLowerCase()) || status.includes(searchQuery.toLowerCase());
  });

  return (
    <div className="container mr-0 ml-0 p-2 space-y-8">
      <div className="container  mx-auto px-4 py-2">

        <header className="mb-6 bg-gray-50 p-4 rounded-lg shadow-md flex items-center justify-between dark:bg-secondaryDark">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-dark">Contract Management</h1>

          {/* Search Bar */}
          <div className="w-full max-w-xs">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery} // Bind searchQuery to input value
              onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery when typing
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </header>
        {/* Contract Expiry Notification */}
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-3 mb-6 flex flex-wrap justify-between items-center" role="alert">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setShowForm(true)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded dark:bg-secondaryDark"
            >
              Add New Contract
            </button>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-4 flex-grow">
            <p className="font-bold">Contracts Expiring Soon</p>
            <p>You have 2 contracts expiring in the next 30 days. <a href="#" className="underline">Review now</a></p>
          </div>
        </div>

        {/* Contract Table */}
        <div className="  rounded-lg shadow-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-50 dark:bg-secondaryDark">
              <thead>
                <tr className="bg-white-200 text-black-600 border-b border-gray-200   uppercase text-sm leading-normal dark:bg-primaryDark dark:text-gray-50">
                  <th className="py-3 px-6 text-left">Contract ID</th>
                  <th className="py-3 px-6 text-left">Vendor Name</th>
                  <th className="py-3 px-6 text-left">Start Date</th>
                  <th className="py-3 px-6 text-left">End Date</th>
                  <th className="py-3 px-5 text-left">Renewal Reminder</th>
                  <th className="py-3 px-6 text-left">Status</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-black text-md ">
                {filteredContracts.map((contract) => (
                  <tr key={contract.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-left whitespace-nowrap">{contract.id}</td>
                    <td className="py-3 px-6 text-left">{contract.vendor.name}</td>
                    <td className="py-3 px-6 text-left">{contract.startDate}</td>
                    <td className="py-3 px-6 text-left">{contract.endDate}</td>
                    <td className="py-3 px-6 text-middle">{contract.renewRemainder} days</td>
                    <td className="py-3 px-6 text-left">
                      <span className={`${contract.status === 'Active' ? 'bg-green-200 text-green-600' :
                        contract.status === 'Expired' ? 'bg-red-200 text-red-600' :
                          'bg-blue-200 text-blue-600'
                        } py-1 px-3 rounded-full text-xs`}>
                        {contract.status}
                      </span>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <button onClick={() => handleViewContract(contract)} className=" p-2 rounded mr-2 flex items-center">
                          <AiFillEye className='text-green-600' />

                        </button>
                        <button onClick={() => handleEditContract(contract)} className=" p-2 rounded mr-2 flex items-center">
                          <AiFillEdit className='text-yellow-600' />
                        </button>
                        <button className=" p-2 rounded mr-2 flex items-center">
                          <BiRefresh className='text-blue-600' />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


        {/* View Contract Detail Modal */}
        {showModal && selectedContract && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
            <div className="relative w-full max-w-lg p-6 bg-gray-50 rounded-lg shadow-lg text-gray-800 dark:bg-secondaryDark ">
              <div className="mb-4">
                <h2 className="text-2xl font-semibold  text-center">
                  Contract Details
                </h2>
              </div>
              <div className="space-y-4">
                <div className="flex justify-between">
                  <strong>Contract ID:</strong>
                  <span>{selectedContract.id}</span>
                </div>
                <div className="flex justify-between">
                  <strong>Vendor:</strong>
                  <span>{selectedContract.vendor?.name || 'N/A'}</span>
                </div>
                <div className="flex justify-between">
                  <strong>Start Date:</strong>
                  <span>{selectedContract.startDate || 'N/A'}</span>
                </div>
                <div className="flex justify-between">
                  <strong>End Date:</strong>
                  <span>{selectedContract.endDate || 'N/A'}</span>
                </div>
                <div className="flex justify-between">
                  <strong>Renewal Reminder:</strong>
                  <span>{selectedContract.renewRemainder || 'N/A'} days</span>
                </div>
                <div className="flex justify-between">
                  <strong>Status:</strong>
                  <span>{selectedContract.status || 'N/A'}</span>
                </div>
                <div className="flex justify-between">
                  <strong>Contract Terms:</strong>
                  <span>{contractTerms || 'N/A'}</span>
                </div>
              </div>

              <div className="mt-6 flex justify-center">
                <button
                  className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-200"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}





        {/* Add/Edit Contract Form */}
        {showForm && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="relative p-6 bg-gray-50 rounded-lg shadow-lg w-full max-w-lg mx-4 dark:bg-secondaryDark">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4 dark:text-dark">
                {selectedContract ? 'Edit Contract' : 'Add New Contract'}
              </h3>

              <form onSubmit={handleSaveContract} className='dark:text-dark text-gray-700' >
                {/* Vendor Selection */}
                <div className="mb-2">
                  <label htmlFor="vendor" className="block  font-semibold mb-2">
                    Vendor
                  </label>
                  <Select
                    value={vendor}
                    onChange={handleVendorChange}
                    options={vendors}
                    placeholder="Select Vendor"
                    isClearable
                    isSearchable
                    className="border rounded-lg w-full text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                  />
                </div>

                {/* Contract Terms */}
                <div className="mb-2">
                  <label htmlFor="terms" className="block  font-semibold mb-2">
                    Contract Terms
                  </label>
                  <textarea
                    id="terms"
                    rows="3"
                    value={contractTerms}
                    onChange={(e) => setContractTerms(e.target.value)}
                    required
                    className="border rounded-lg w-full py-2 px-3 text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                  />
                </div>

                {/* Start Date and End Date in one row */}
                <div className="mb-2 flex gap-4">
                  <div className="w-1/2">
                    <label htmlFor="startDate" className="block  font-semibold mb-2">
                      Start Date
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                      className="border rounded-lg w-full py-2 px-3 text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                    />
                  </div>
                  <div className="w-1/2">
                    <label htmlFor="endDate" className="block  font-semibold mb-2">
                      End Date
                    </label>
                    <input
                      type="date"
                      id="endDate"
                      onChange={(e) => setEndDate(e.target.value)}
                      min={startDate}
                      required
                      className="border rounded-lg w-full py-2 px-3 text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                    />
                  </div>
                </div>

                {/* Renewal Reminder and Status in one row */}
                <div className="mb-2 flex gap-4">
                  <div className="w-1/2">
                    <label htmlFor="reminder" className="block  font-semibold mb-2">
                      Renewal Reminder (Days)
                    </label>
                    <select
                      id="reminder"
                      onChange={(e) => setReminder(e.target.value)}
                      required
                      className="border rounded-lg w-full py-2 px-3 text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                    >
                      <option disabled selected>Select</option>
                      <option value="30">30 days before</option>
                      <option value="60">60 days before</option>
                      <option value="90">90 days before</option>
                    </select>
                  </div>
                  <div className="w-1/2">
                    <label htmlFor="status" className="block  font-semibold mb-2">
                      Status
                    </label>
                    <select
                      id="status"
                      onChange={(e) => setStatus(e.target.value)}
                      className="border rounded-lg w-full py-2 px-3 text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                    >
                      <option value="Active">Active</option>
                      <option value="Expired">Expired</option>
                      <option value="Renewed">Renewed</option>
                    </select>
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-end mt-4">
                  <button type="submit" className="mr-3 px-5 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 dark:bg-dark dark:hover:bg-primaryDark">
                    Save
                  </button>
                  <button
                    onClick={() => setShowForm(false)}
                    className="px-5 py-2 bg-gray-500 text-white rounded-lg shadow hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>


        )}
      </div>
    </div>
  )
}
