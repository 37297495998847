// CustomReports.js
import React, { useState } from 'react';
import { Download } from 'react-feather';
import { format } from 'date-fns';

const CustomReports = ({ exportToPDF, exportToExcel, exportToCSV, data }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-2">Custom Reports</h2>
      <p className="text-gray-600 mb-4">Generate custom reports based on your requirements.</p>
      <div className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="space-y-2">
            <label htmlFor="project" className="block text-sm font-medium text-gray-700">Select Project</label>
            <select id="project" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md">
              <option>Select project</option>
              <option value="project1">Project 1</option>
              <option value="project2">Project 2</option>
            </select>
          </div>
          <div className="space-y-2">
            <label htmlFor="department" className="block text-sm font-medium text-gray-700">Select Department</label>
            <select id="department" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md">
              <option>Select department</option>
              <option value="sales">Sales</option>
              <option value="finance">Finance</option>
            </select>
          </div>
          <div className="space-y-2">
            <label htmlFor="report-type" className="block text-sm font-medium text-gray-700">Report Type</label>
            <select id="report-type" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md">
              <option>Select report type</option>
              <option value="project-specific">Project-Specific</option>
              <option value="department-wise">Department-Wise</option>
              <option value="sales">Sales</option>
              <option value="revenue">Revenue</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="flex-1 space-y-2">
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm rounded-md"
              value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </div>
          <div className="flex-1 space-y-2">
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm rounded-md"
              value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </div>
        </div>
        <div className="space-y-2">
          <label htmlFor="filter" className="block text-sm font-medium text-gray-700">Filter</label>
          <select id="filter" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm rounded-md">
            <option>Select filter</option>
            <option value="last7days">Last 7 days</option>
            <option value="thismonth">This Month</option>
            <option value="lastmonth">Last Month</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <button className="w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Generate Report
        </button>

        <div className="flex flex-wrap gap-2">
          <button onClick={exportToPDF} className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <Download className="mr-2 h-4 w-4" />
            Export as PDF
          </button>
          <button onClick={exportToExcel} className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <Download className="mr-2 h-4 w-4" />
            Export as Excel
          </button>
          <button onClick={exportToCSV} className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <Download className="mr-2 h-4 w-4" />
            Export as CSV
          </button>
        </div>
        <div className="border rounded p-4">
          <table className="min-w-full table-auto border-collapse bg-white shadow-lg rounded-lg overflow-hidden">
            <thead className="bg-gray-100 text-black">
              <tr>
                <th className="px-4 py-2 text-left">ID</th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Age</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.id} className="even:bg-gray-100 hover:bg-gray-100 transition-colors duration-300">
                  <td className="border px-4 py-2">{item.id}</td>
                  <td className="border px-4 py-2">{item.name}</td>
                  <td className="border px-4 py-2">{item.age}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomReports;
