import Counting from "../components/Counting";
import ExecuteCard from "../components/ExecuteCard";
import ExecutionCards from "../components/ExecutionCards";
import FrameComponent2 from "../components/frame-component2";
import FrameComponent4 from "../components/frame-component4";
import ImageSlider from "../components/ImageSlider";
import JoinUs from "../components/JoinUs";
import ProjectHero from "../components/ProjectHero";
import "./PROJECTPAGE.css";

const Projectpage = () => {


  const imageArray = [
    '/siteimages/absolute-company-4.jpeg',
    '/siteimages/nacl-1.jpeg',
    '/siteimages/industry1.jpeg',
    '/siteimages/meeting-room3.jpeg',
    '/siteimages/office3.jpeg',
    '/siteimages/room-w-tbl1.jpeg',
    '/siteimages/restaurant-6.jpeg',
    '/siteimages/temple-1.jpeg',
    '/siteimages/wall-design.jpeg',
    '/siteimages/statue1.jpeg',
    '/siteimages/company-entrance1.jpeg',
    '/siteimages/tv-cabinet1.jpeg',

  ];

  return (
    <div className="project-page">
      <section className="main">
        <div className="hero-section border-t">

          <ProjectHero />
        </div>
      </section>


      <Counting />

      <section className="what-we-did-a-showcase-wrapper">
        <h1 className="what-we-did">What We Did - A Showcase</h1>
        <ImageSlider images={imageArray} isLTR={false} />
        <ImageSlider images={imageArray} isLTR={true} />
      </section>




      <FrameComponent4 />
      <div className="how-we-execute-wrapper">
        <h1 className="how-we-execute">How We Execute</h1>
      </div>
      <section className="process">
        <div className="at-our-interior">{`Transforming spaces through a step-by-step approach that blends creativity, precision, and collaboration at every stage. `}</div>
      </section>
      {/* <section className="execution-cards"> */}
      <ExecutionCards />
      {/* </section> */}
      <FrameComponent2 />

      <div className="flex w-full p-4 justify-center">

        <JoinUs />
      </div>


    </div>
  );
};

export default Projectpage;
