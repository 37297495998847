import React, { useState } from 'react';
import SupplierManagement from './suppliermng';
import SupplierModal from './SupplierModal';
import SupplierPerformanceTracking from './SupplierPerformanceTracking';
import AddSupplierProduct from './AddSupplierProduct';

const SupplierManagementLayout = () => {
    const [activeTable, setActiveTable] = useState("supplier");
    const [activeForm, setActiveForm] = useState("supplier");

    const [product, setProduct] = useState([]);

    const addProduct = (newProduct) => {
        setProduct((prevProduct) => [...prevProduct, newProduct]);
    };

    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl">
                {/* Left side - Form Selection */}
                <div className="h-[calc(100vh-4rem)]  overflow-y-auto">
                    <div className="mt-1">
                        <div className="flex space-x-4"> {/* Buttons to toggle forms */}
                            <button
                                className={`flex-1 p-2 text-sm text-center rounded-lg ${activeForm === 'supplier' ? 'bg-blue-500 text-white dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveForm('supplier')}
                            >
                                Add Supplier
                            </button>
                            <button
                                className={`flex-1 p-2 text-sm text-center rounded-lg ${activeForm === 'product' ? 'bg-blue-500 text-white dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveForm('product')}
                            >
                                Supply Product
                            </button>
                        </div>

                        {/* Render the active form */}
                        <div className="mt-4">
                            {activeForm === 'supplier' && <SupplierModal />}
                            {activeForm === 'product' && <AddSupplierProduct addNewProduct={addProduct} />}
                        </div>
                    </div>
                </div>

                {/* Right side - Table Selection */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto">
                    <div className="pt-1">
                        <div className="flex space-x-4"> {/* Buttons to toggle tables */}
                            <button
                                className={`flex-1 p-2 text-sm text-center rounded-lg ${activeTable === 'supplier' ? 'bg-blue-500 text-white dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveTable('supplier')}
                            >
                                Supplier Database
                            </button>
                            <button
                                className={`flex-1 p-2 text-sm text-center rounded-lg ${activeTable === 'performance' ? 'bg-blue-500 text-white dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveTable('performance')}
                            >
                                Supplier Performance
                            </button>
                        </div>

                        {/* Render the active table */}
                        <div className="mt-4 dark:bg-dark">
                            {activeTable === 'supplier' && <SupplierManagement products={product} />}
                            {activeTable === 'performance' && <SupplierPerformanceTracking />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierManagementLayout;
