import React from 'react';
import './VisionMission.css';
import { FaLeaf, FaHandshake, FaAccessibleIcon  } from 'react-icons/fa'; // Import icons from react-icons

const VisionMission = () => {
    return (
        <div className="vector-parent">
            <img className="frame-child" alt="" src="images/line206936-lzqn.svg" />
            <div className="section-title-parent">
            <div className="section-title">
                <div className="section-icon"><FaLeaf /></div> {/* Icon for Mission */}
                <div className="our-mission">Our Mission & Vision</div>
                <div className="text1">{`At Kala Interiors, our mission is to transform spaces into beautiful, functional, and personalized environments that reflect the unique tastes and lifestyles of our clients. We are committed to delivering exceptional interior design solutions with a focus on quality, creativity, and customer satisfaction. Our vision is to be a leading name in the interior design industry, renowned for our innovative designs and dedication to creating spaces that inspire and enrich the lives of those who inhabit them.`}</div>
            </div>
            </div>
            <img className="frame-child" alt="" src="images/line206936-lzqn.svg" />
            <div className="section-title-parent">
                <div className="section-title">
                    <div className="section-icon"><FaHandshake /></div> {/* Icon for Values */}
                    <div className="our-values">Our Values</div>
                </div>
                <div className="text1">{`We believe in the importance of understanding our clients' needs and exceeding their expectations through our commitment to integrity, transparency, and excellence. Our values are centered around collaboration, creativity, and sustainability. We strive to create designs that not only enhance the aesthetic appeal of a space but also ensure functionality and sustainability for the long-term benefit of our clients and the environment.`}</div>
            </div>
            <img className="frame-child" alt="" src="images/line206936-lzqn.svg" />
            <div className="section-title-parent">
                <div className="section-title">
                    <div className="section-icon"><FaAccessibleIcon  /></div> {/* Icon for Sustainability */}
                    <div className="our-values">Sustainability & Accessibility</div>
                </div>
                <div className="text1">{`Sustainability is at the heart of our design philosophy. We are dedicated to incorporating eco-friendly materials and practices that reduce environmental impact and promote energy efficiency. Accessibility is also a key consideration in our designs, ensuring that all spaces are inclusive and accommodate the needs of all users, regardless of age or ability. Our goal is to create interiors that are not only beautiful and functional but also contribute positively to our planet and community.`}</div>
            </div>
            <img className="frame-child" alt="" src="images/line206936-lzqn.svg" />
        </div>
    );
};

export default VisionMission;
