// import axios from "axios";
// import { jwtDecode } from 'jwt-decode';
// import { useHistory } from 'react-router-dom'; // Make sure you have react-router-dom installed


// const axiosInstance = axios.create({
//     baseURL: process.env.REACT_APP_API_BASE_URL,
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json'
//     }
// });

// // Check if the token is valid
// const isTokenValid = (token) => {
//     try {
//         const decodedToken = jwtDecode(token);
//         const currentTime = Date.now() / 1000; // Current time in seconds
//         return decodedToken.exp && decodedToken.exp > currentTime; // Valid if not expired
//     } catch (error) {
//         console.error("Token is invalid:", error);
//         return false;
//     }
// };

// axiosInstance.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');


//             config.headers['Authorization'] = `Bearer ${token}`;


//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// export default axiosInstance;


import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

// Check if the token is valid
const isTokenValid = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp && decodedToken.exp > currentTime;
    } catch (error) {
        console.error("Token is invalid:", error);
        return false;
    }
};

// Intercept request to add Authorization header if token exists
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");

        if (token && isTokenValid(token)) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercept response to handle expired token
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const history = useNavigate();

        // Check if the error response indicates an authentication issue
        if (error.response && (error.response.status === 401 || error.response.status === 401)) {
            const token = localStorage.getItem("token");

            // If token exists but is invalid/expired, remove it and redirect to login
            if (token && !isTokenValid(token)) {
                localStorage.removeItem("token");
                history("/login"); // Redirect to the login page
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
