import PerformanceManagement from "./PerformanceManagement"
import PerformanceReview from "./PerformanceReview"

const HrmPerformanceLayout = () => {
    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark overflow-y-scroll">
             <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full max-w-8xl h-[calc(100vh-4rem)] overflow-y-scroll"> 
            {/* Left side - Sales Pipeline Component */}
            <PerformanceReview/>

            {/* Right side - Kanban Board and Deal Table */}
             <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto"> 
            <PerformanceManagement />
            
           
             </div> 
             </div> 
        </div>
    )
}

export default HrmPerformanceLayout