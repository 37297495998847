import ControlsStreetView from "./controls-street-view";
import PropTypes from "prop-types";
import styles from "./maps-full-map.module.css";

const MapsFullMap = ({ className = "", onContactClick }) => {
  return (
    <div className={[styles.mapsFullMap, className].join(" ")}>
      <div className="flex flex-col gap-2 mb-2">

        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.819664476311!2d73.80463877496567!3d18.627179282487237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9ace5c2babb%3A0xef9f78d5188837bd!2sAshdip%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1725344499384!5m2!1sen!2sin" width={'300px'} height={'300px'} style={{ border: '0' }} loading="lazy" /> */}

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1890.3958978307482!2d73.848612!3d18.630966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9f8a23d41cd%3A0x9a5c98d4f111dd3e!2sIndrayani%20Nagar%2C%20Bhosari%2C%20Pune%2C%20Maharashtra%20411026!5e0!3m2!1sen!2sin!4v1728000000000!5m2!1sen!2sin"
          width="500"
          height="300"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />


        <button className={styles.contactUs} onClick={onContactClick}>
          Contact Us
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/images/right-arrow-white.svg"
          />
        </button>
      </div>
    </div>
  );
};

MapsFullMap.propTypes = {
  className: PropTypes.string,
};

export default MapsFullMap;
