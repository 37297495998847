import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Download } from 'lucide-react';
import axiosInstance from '../../services/AxiosInstance';
import jsPDF from 'jspdf';

export default function PayrollManagement() {
  const [employee, setEmployee] = useState(null);
  const [filteredPayroll, setFilteredPayroll] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emptyMessage, setEmptyMessage] = useState(false);

  const itemsPerPage = 6;

  const fetchPayrollData = async () => {
    setLoading(true);
    setError(null);
    setEmptyMessage(false);
    const email = localStorage.getItem('email');

    try {
      const response = await axiosInstance.get(`/backend/api/employees/email/${email}`);
      if (response.data) {
        setEmployee(response.data);
        filterAndSortPayrollData(response.data.payrollManagementList);
      } else {
        setEmptyMessage(true);
      }
    } catch (err) {
      setError('Error fetching payroll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayrollData();
  }, []);

  useEffect(() => {
    if (employee) {
      filterAndSortPayrollData(employee.payrollManagementList);
    }
  }, [selectedMonth, selectedYear]);

  const handleMonthChange = (e) => setSelectedMonth(e.target.value === "all" ? null : Number(e.target.value));
  const handleYearChange = (e) => setSelectedYear(e.target.value === "all" ? null : Number(e.target.value));

  const filterAndSortPayrollData = (payrollList) => {
    const filtered = payrollList.filter(
      (payroll) =>
        (selectedMonth === null || payroll.paymentMonth === selectedMonth) &&
        (selectedYear === null || payroll.paymentYear === selectedYear)
    );

    setEmptyMessage(filtered.length === 0);

    const sorted = filtered.sort((a, b) => {
      if (a.paymentYear !== b.paymentYear) return b.paymentYear - a.paymentYear;
      return b.paymentMonth - a.paymentMonth;
    });

    setFilteredPayroll(sorted);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPayrolls = filteredPayroll.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredPayroll.length / itemsPerPage);

  const generatePayslipPDF = (payroll) => {
    const doc = new jsPDF();
    doc.setFont('Helvetica');
    doc.setFontSize(12);

    const imgData = `${window.location.origin}/images/Kala_Int_PNGLogo.png`;
    doc.addImage(imgData, 'PNG', 10, 10, 30, 30);

    doc.setFontSize(18);
    doc.setFont('Helvetica', 'bold');
    doc.text('Payslip', 105, 20, null, null, 'center');
    doc.setLineWidth(0.5);
    doc.line(10, 45, 200, 45);

    doc.setFillColor(235, 235, 235);
    doc.rect(10, 50, 190, 40, 'F');
    doc.setFont('Helvetica', 'bold');
    doc.text('Employee Details', 10, 60);
    doc.setFont('Helvetica', 'normal');
    doc.text(`Name: ${employee.fullName}`, 10, 70);
    doc.text(`Employee ID: ${employee.employeeId}`, 10, 80);
    doc.text(`Department: ${employee.department}`, 10, 90);

    doc.setLineWidth(0.5);
    doc.line(10, 95, 200, 95);

    doc.setFont('Helvetica', 'bold');
    doc.text('Payroll Information', 10, 105);
    doc.setFont('Helvetica', 'normal');
    doc.text(`Month: ${new Date(0, payroll.paymentMonth - 1).toLocaleString('default', { month: 'long' })}`, 10, 115);
    doc.text(`Year: ${payroll.paymentYear}`, 10, 125);
    doc.text(`Status: ${payroll.status}`, 10, 135);

    doc.setLineWidth(0.5);
    doc.line(10, 140, 200, 140);

    doc.setFillColor(245, 245, 245);
    doc.rect(10, 145, 190, 40, 'F');
    doc.setFont('Helvetica', 'bold');
    doc.text('Payroll Breakdown:', 10, 155);
    doc.setFont('Helvetica', 'normal');
    doc.text(`Base Salary: ${payroll.baseSalary.toLocaleString()}`, 10, 165);
    doc.text(`Bonuses: ${payroll.bonuses.toLocaleString()}`, 10, 175);
    doc.text(`Deductions: ${payroll.deductions.toLocaleString()}`, 10, 185);

    doc.setLineWidth(0.5);
    doc.line(10, 190, 200, 190);

    const netPay = payroll.baseSalary + payroll.bonuses - payroll.deductions;
    doc.setFont('Helvetica', 'normal');
    doc.text('Net Pay:', 10, 200);
    doc.text(`${netPay.toLocaleString()}`, 50, 200);

    doc.setLineWidth(0.5);
    doc.line(10, 205, 200, 205);

    doc.setFontSize(10);
    doc.setFont('Helvetica', 'normal');
    doc.text('Thank you for working with us!', 10, 215);
    doc.text('Kala Interiors | Phone -8378989511, 8378989507 | Email- hemalandge@gmail.com, amit.s.landge@gmail.com', 10, 220);

    doc.save(`Payslip_${employee.fullName}_${payroll.paymentMonth}_${payroll.paymentYear}.pdf`);
  };

  return (
    <div className="container mx-auto p-6 space-y-8 bg-gray-100 rounded-lg shadow-md">
      <div className="bg-white p-4 rounded-lg shadow mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Payroll Management</h1>
      </div>

      <div className="flex items-center space-x-4 mb-4">
        <label>
          Month:
          <select value={selectedMonth === null ? "all" : selectedMonth} onChange={handleMonthChange} className="ml-2 p-2 rounded-md">
            <option value="all">All</option>
            {[...Array(12)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {new Date(0, i).toLocaleString('default', { month: 'long' })}
              </option>
            ))}
          </select>
        </label>
        <label>
          Year:
          <select value={selectedYear === null ? "all" : selectedYear} onChange={handleYearChange} className="ml-2 p-2 rounded-md">
            <option value="all">All</option>
            {[...Array(5)].map((_, i) => (
              <option key={i} value={new Date().getFullYear() - i}>
                {new Date().getFullYear() - i}
              </option>
            ))}
          </select>
        </label>
      </div>

      {loading &&
        <div className="flex justify-center items-center gap-3">
          <div className="animate-spin rounded-full border-t-4 border-blue-600 w-16 h-16"></div>
          <p className="text-gray-500 text-center">Loading payroll data...</p>

        </div>
      }
      {error && <p className="text-red-500 text-center">{error}</p>}
      {emptyMessage && !loading && !error && <p className="text-gray-500 text-center">No payroll data found for the selected filter.</p>}

      {!loading && !error && !emptyMessage && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {currentPayrolls.map((payroll, index) => {
              const netPay = payroll.baseSalary + payroll.bonuses - payroll.deductions;
              const statusColor = payroll.status === 'Paid' ? 'bg-green-100 text-green-600' :
                payroll.status === 'Pending' ? 'bg-yellow-100 text-yellow-600' :
                  'bg-red-100 text-red-600';

              return (
                <div key={index} className="relative bg-white rounded-xl border border-gray-200 shadow-lg p-5 hover:shadow-xl transition-shadow duration-300">
                  <span className={`absolute top-3 right-3 px-3 py-1 text-xs font-semibold rounded-full ${statusColor}`}>
                    {payroll.status}
                  </span>

                  <div className="flex items-center space-x-3 mb-4">
                    <h3 className="text-xl font-semibold text-gray-700">{employee?.fullName}</h3>
                  </div>

                  <p className="text-gray-500 mb-2">
                    <span className="font-medium text-gray-700">Base Salary:</span> ₹{payroll.baseSalary.toLocaleString()}
                  </p>
                  <p className="text-gray-500 mb-2">
                    <span className="font-medium text-gray-700">Bonuses:</span> ₹{payroll.bonuses.toLocaleString()}
                  </p>
                  <p className="text-gray-500 mb-2">
                    <span className="font-medium text-gray-700">Deductions:</span> ₹{payroll.deductions.toLocaleString()}
                  </p>
                  <p className="text-lg font-semibold text-gray-800 mt-3">
                    Net Pay: <span className="text-indigo-600">₹{netPay.toLocaleString()}</span>
                  </p>

                  <button
                    onClick={() => generatePayslipPDF(payroll)}
                    className="flex items-center justify-center mt-4 px-3 py-2 rounded-md text-indigo-600 border border-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-300"
                  >
                    <Download className="mr-2" />
                    Download Payslip
                  </button>
                </div>
              );
            })}
          </div>

          <div className="flex justify-center mt-6">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="mr-2 px-4 py-2 rounded-lg text-gray-600 bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
              disabled={currentPage === 1}
            >
              <ChevronLeft />
            </button>
            <span className="text-gray-700">Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className="ml-2 px-4 py-2 rounded-lg text-gray-600 bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
              disabled={currentPage === totalPages}
            >
              <ChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
