import HeroSection from "../components/hero-section";
import Gallery from "../components/gallery";
import FrameComponent3 from "../components/frame-component3";
import FrameComponent4 from "../components/frame-component4";
import FrameComponent2 from "../components/frame-component2";
import styles from "./desktop1280x.module.css";
import JoinUs from "../components/JoinUs";
import ContactModal from "../components/ContactModal";
import GetQuoteModal from "../components/GetQuoteModal"; // Assuming you have this component
import { useState } from "react";
import ExecutionCards from "../components/ExecutionCards";

const Desktop1280x = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false); // Contact Modal State
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);     // Get Quote Modal State

  // Function to open the contact modal
  const openContactModal = () => {
    setIsContactModalOpen(true);
    setIsQuoteModalOpen(false); 
  };

  // Function to close the contact modal
  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };

  // Function to open the Get Quote modal
  const openQuoteModal = () => {
    setIsQuoteModalOpen(true);
    setIsContactModalOpen(false);
  };

  // Function to close the Get Quote modal
  const closeQuoteModal = () => {
    setIsQuoteModalOpen(false);
  };

  return (
    <div className={styles.desktop1280x800}>
      <HeroSection onContactClick={openContactModal} onGetQuoteClick={openQuoteModal} /> {/* Add onGetQuoteClick */}
      <Gallery />
      <FrameComponent3 />
      <FrameComponent4 />
      <div className={styles.desktop1280x800Child} />

      <div className="how-we-execute-wrapper">
        <h1 className="how-we-execute" style={{ fontSize: 'xx-large' }}>How We Execute</h1>
      </div>
      <section className="process">
        <div className="at-our-interior">
          {`Transforming spaces through a step-by-step approach that blends creativity, precision, and collaboration at every stage.`}
        </div>
      </section>
      <ExecutionCards />

      <FrameComponent2 />
      <JoinUs />

      {/* Render Contact Modal */}
      {isContactModalOpen && (
        <ContactModal onCloseContact={closeContactModal} openContact={isContactModalOpen} />
      )}

      {/* Render Get Quote Modal */}
      {isQuoteModalOpen && 
      <GetQuoteModal onCloseQuote={closeQuoteModal} open={isQuoteModalOpen} />}

    </div>
  );
};

export default Desktop1280x;
