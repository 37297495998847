import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

export default function SupplierModal() {
  const [formValues, setFormValues] = useState({
    supplierName: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await axiosInstance.post('/backend/api/suppliers', {
        name: formValues.supplierName,
        contactPerson: formValues.contactName,
        contactNumber:formValues.contactPhone,
        email:formValues.contactEmail,
        address: formValues.address
      });
      setSuccessMessage('Supplier added successfully!');
      setFormValues({
        supplierName: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        address: '',
      });
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || 'Failed to add supplier. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 rounded-lg shadow-lg max-w-3xl mx-auto dark:bg-secondaryDark">
      <div className="text-center">
        <h3 className="text-2xl leading-6 font-semibold text-gray-900 mb-4 dark:text-dark">
          Add New Supplier
        </h3>

        <form onSubmit={handleSubmit} className="bg-gray-50 p-3 rounded-lg shadow-md dark:bg-secondaryDark text-gray-700 dark:text-dark">
          {/* Supplier Information Section */}
          <div className="mb-6">
            <h4 className="text-lg font-medium  mb-2 border-b pb-2">
              Supplier Information
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div className="mb-2">
                <label htmlFor="supplierName" className="block text-left text-sm font-medium ">
                  Supplier Name
                </label>
                <input
                  type="text"
                  id="supplierName"
                  name="supplierName"
                  value={formValues.supplierName}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="contactName" className="block text-left text-sm font-medium ">
                  Contact Name
                </label>
                <input
                  type="text"
                  id="contactName"
                  name="contactName"
                  value={formValues.contactName}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
          </div>

          {/* Contact Information Section */}
          <div className="mb-2">
            <h4 className="text-lg font-medium  mb-2 border-b pb-2">
              Contact Information
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="contactEmail" className="block text-left text-sm font-medium ">
                  Contact Email
                </label>
                <input
                  type="email"
                  id="contactEmail"
                  name="contactEmail"
                  value={formValues.contactEmail}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="contactPhone" className="block text-left text-sm font-medium ">
                  Contact Phone Number
                </label>
                <input
                  type="tel"
                  id="contactPhone"
                  name="contactPhone"
                  value={formValues.contactPhone}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="address" className="block text-left text-sm font-medium text-">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          {/* Submit Button */}
          <div className="text-right">
            <button
              className={`px-6 py-2 text-white text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700 dark:bg-dark dark:hover:bg-primaryDark'
                }`}
              type="submit"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>

          {/* Success/Error Message */}
          {successMessage && (
            <div className="mt-4 text-green-500 text-sm font-medium">{successMessage}</div>
          )}
          {errorMessage && (
            <div className="mt-4 text-red-500 text-sm font-medium">{errorMessage}</div>
          )}
        </form>
      </div>
    </div>
  );
}
