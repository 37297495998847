import React from 'react'


const RestockTable = () => {
    // Mock data for restock notifications
    const restockNotifications = [
        { id: 1, name: 'Widget A', quantity: 50, expectedDate: '2023-10-15' },
        { id: 2, name: 'Gadget B', quantity: 100, expectedDate: '2023-10-20' },
        { id: 3, name: 'Part D', quantity: 75, expectedDate: '2023-10-18' },
    ];


    

    return (
        <div className="overflow-x-auto bg-white shadow-md rounded-lg mt-8">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Product
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Quantity
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Expected Date
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {restockNotifications.map((notification) => (
                        <tr key={notification.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{notification.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{notification.quantity}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{notification.expectedDate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default RestockTable