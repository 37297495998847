import React, { useState, useEffect } from 'react';
import DealsKanbanBoard from './DealsKanbanBoard';
import DealTable from './DealTable';
import SalesPipeline from './SalesPipeline'; // Import SalesPipeline form component
import axiosInstance from '../../services/AxiosInstance';

const SalePipelineLayout = () => {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leadLoading, setLeadLoading] = useState(true);
  const [error, setError] = useState('');
  const [customers, setCustomers] = useState([]);
  const [activeTab, setActiveTab] = useState('kanban');
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/backend/api/contact/customer');
        setCustomers(response.data);
      } catch (err) {
        setError('Failed to load customers. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    const fetchDeals = async () => {
      setLeadLoading(true);
      try {
        const response = await axiosInstance.get('/backend/api/leads');
        setDeals(response.data);
      } catch (error) {
        setError('Failed to fetch deals.');
      } finally {
        setLeadLoading(false);
      }
    };

    fetchCustomers();
    fetchDeals();
  }, []);

  useEffect(() => {
    // Function to close the modal when the 'Esc' key is pressed
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };

    // Attach the event listener to document
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);



  const addNewLead = (newLead) => {
    setDeals((prev) => Array.isArray(newLead) ? [...prev, ...newLead] : [...prev, newLead]);
    setShowModal(false)
  };

  const handleStatusChange = (id, status) => {
    setDeals(prev => prev.map(deal => (deal.id === id ? { ...deal, status } : deal)));
  };

  return (
    <div className="flex flex-col items-center mt-16 min-h-screen bg-gray-100 dark:bg-dark overflow-auto">
      {/* Tab Buttons */}
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-2 px-4 w-full sm:w-auto flex-wrap">
        <button
          onClick={() => setShowModal(true)} // Open modal on button click
          className="py-2 px-4 rounded bg-green-500 text-white w-full sm:w-auto"
        >
          Add New Lead
        </button>
        <button
          onClick={() => setActiveTab('kanban')}
          className={`py-2 px-4 rounded ${activeTab === 'kanban' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} w-full sm:w-auto`}
        >
          Deal Tracking (Kanban)
        </button>
        <button
          onClick={() => setActiveTab('table')}
          className={`py-2 px-4 rounded ${activeTab === 'table' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} w-full sm:w-auto`}
        >
          All Deal
        </button>
      </div>

      {/* Active Tab Content */}
      <div className="flex justify-center items-start w-full max-w-screen-xl overflow-auto">
        {activeTab === 'kanban' ? (
          <DealsKanbanBoard leads={deals} loading={leadLoading} error={error} updateStatus={handleStatusChange} />
        ) : (
          <DealTable deals={deals} loading={leadLoading} error={error} />
        )}
      </div>

      {/* Modal for Sales Pipeline Form */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-50 dark:bg-secondaryDark dark:text-dark rounded-lg shadow-lg  w-full max-w-md">
            <button
              onClick={() => setShowModal(false)}
              className=" text-3xl text-red-500 hover:text-gray-700 mr-4 float-right "
            >
              &times;
            </button>
            <SalesPipeline
              newLead={addNewLead}
              error={error}
              customers={customers}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SalePipelineLayout;
