import React from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = ({ images, isLTR , onImageChange}) => {



    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        rtl: !isLTR,
        centerMode: true, // Enabling center mode for spacing
        centerPadding: '30px', // Adjust padding for spacing
        responsive: [
            {
                breakpoint: 1024, // Tablet and above
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '20px',
                }
            },
            {
                breakpoint: 768, // Mobile (large)
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '15px',
                }
            },
            {
                breakpoint: 480, // Mobile (small)
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '10px',
                }
            }
        ],
        afterChange: (index) => {
            // Call the parent's onImageChange function to update the active dot in Gallery
            if (onImageChange) {
                onImageChange(index);
            }
        }
    };



    return (
        <div className='w-full m-auto px-6'>
            <div className="">
                <Slider {...settings}>
                    {
                        images.map((image, index) => (
                            <div key={index} className="bg-white text-black rounded-xl mb-4 px-1 mx-2"> {/* Horizontal margin for spacing */}
                                <div className="w-full h-full flex justify-center items-center rounded-xl  overflow-hidden">
                                    <img src={image} alt={`image-${index}`} className='w-[400px] h-[300px] object-cover rounded-lg' />
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    );
}

export default ImageSlider;
