import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';

const QuotationsInvoicing = ({ newQuotation, customers }) => {
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('')

  const [quotationName, setQuotationName] = useState('');


  const [rows, setRows] = useState([{ quantity: '1', description: '', amount: '', discount: '', totalAmount: '' }]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const generatePdf = () => {
    return new Promise((resolve) => {
      const doc = new jsPDF();

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(22);
      doc.text('Kala Interiors', 14, 22);

      // Make sure to import or define your logo image
      // const logoHast = 'path/to/your/logo.png'; // Replace with the correct path
      // doc.addImage(logoHast, 'PNG', 90, 0, 30, 30);

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(12);

      doc.text('Address: Office no. 1, Near Madgiri Garden,', 14, 30);
      doc.text('Indrayani Nagar, Bhosari, Pune 411026', 14, 35);
      doc.text('Phone: +91-8378989511', 14, 40);
      doc.text('Email: amit.s.landge@gmail.com', 14, 45);
      doc.text('Website: www.kalainteriors.in', 14, 50);
      doc.text('GST No: Gstin0000000000', 14, 55);

      const selectedQuote = { name: customerName, email: customerEmail, phone: customerPhone };
      console.log(selectedQuote);


      const pageWidth = doc.internal.pageSize.getWidth();
      const marginRight = 14;
      const maxWidth = pageWidth - marginRight;

      const QuoteFor = 'Quote for';
      const quoteNo = 'Quote No : qn56123';
      const name = `Name: ${selectedQuote?.name || 'NA'}`;
      const mobile = `Phone: ${selectedQuote?.phone || 'NA'}`;
      const email = `Email: ${selectedQuote?.email || 'NA'}`;

      doc.setFont('helvetica', 'bold');
      doc.text(QuoteFor, maxWidth - doc.getTextWidth(QuoteFor), 20);
      doc.text(quoteNo, maxWidth - doc.getTextWidth(quoteNo), 30);
      doc.text(name, maxWidth - doc.getTextWidth(name), 35);
      doc.text(mobile, maxWidth - doc.getTextWidth(mobile), 40);
      doc.text(email, maxWidth - doc.getTextWidth(email), 45);

      doc.line(14, 70, 200, 70);

      doc.setFont('Helvetica', 'italic');
      doc.text(`Dear ${selectedQuote?.name}`, 16, 80);
      doc.setFont('Times', 'normal');
      doc.text('We are pleased to provide you with the following quotation:', 20, 85);

      const quoteItems = rows.map((item) => ({
        quantity: item.quantity,
        description: item.description,
        amount: item.amount,
        discount: item.discount,
        finalCost: parseFloat(item.totalAmount || '0'),
      }));

      let subtotal = 0;
      const itemsData = quoteItems.map(item => {
        subtotal += item.finalCost;
        return [item.quantity, item.description, item.amount, item.discount, item.finalCost.toFixed(2)];
      });

      doc.autoTable({
        startY: 95,
        head: [['Quantity', 'Description', 'Unit Price', 'Discount', 'Final Cost']],
        body: itemsData,
        headStyles: { fontStyle: 'bold' },
      });

      const gstRate = 0.18;
      const gstAmount = subtotal * gstRate;
      const grandTotal = subtotal + gstAmount;

      const finalY = doc.lastAutoTable.finalY || 95;
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text(`Subtotal: ${subtotal.toFixed(2)}`, maxWidth - 50, finalY + 10);
      doc.text(`GST (18%): ${gstAmount.toFixed(2)}`, maxWidth - 50, finalY + 15);
      doc.text(`Grand Total: ${grandTotal.toFixed(2)}`, maxWidth - 50, finalY + 20);

      const terms = [
        '1. Payment Terms: 50% advance payment required...',
        '2. Timeline: Development and deployment...',
        '3. Service Duration: This quotation covers a service period of 1 year.',
        '4. Validity: This quotation is valid for 30 days from the date of issue.',
      ];

      let yOffset = finalY + 50;
      terms.forEach(term => {
        doc.setFont('helvetica', 'normal');
        doc.text(term, 14, yOffset);
        yOffset += 10;
      });

      const pdfBlob = doc.output('blob');
      resolve(pdfBlob);
    });
  };

  const addRow = () => {
    setRows([...rows, { quantity: '1', description: '', amount: '', discount: '', totalAmount: '' }]);
  };

  const deleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === 'amount' || field === 'discount') {
      const amount = parseFloat(updatedRows[index].amount) || 0;
      const discount = parseFloat(updatedRows[index].discount) || 0;
      const discountedAmount = amount - (amount * discount / 100);
      updatedRows[index].totalAmount = discountedAmount.toFixed(2);
    }

    setRows(updatedRows);
  };

  const handleCustomer = (customer) => {
    setCustomerName(customer.name || '');
    setCustomerEmail(customer.email || '');
    setCustomerAddress(customer.address || '');
    setCustomerPhone(customer.phone || '');
    setCustomerId(customer.value || '');

  };


  const handleSendQuote = async (e) => {
    e.preventDefault();
    setFormLoading(true);

    try {
      const pdfBlob = await generatePdf();

      // Create FormData to send the PDF and quote details
      const formData = new FormData();
      formData.append("file", pdfBlob, `${customerName}.pdf`);
      formData.append("quoteitems", JSON.stringify(rows));
      formData.append("quotationTitle", JSON.stringify(quotationName));  // Add quotation title to FormData


      // Send the FormData via axios
      const response = await axiosInstance.post(
        `/backend/api/quote-send/${customerId}`, // Ensure you pass the correct quote ID or title
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } } // Set content type for FormData
      );

      // Check the response status
      if (response.status === 200 || response.status === 201) {
        Swal.fire("Success", "Quote sent/updated successfully!", "success");
      } else {
        Swal.fire("Error", "Failed to process quotation.", "error");
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Error", "Failed to process quotation.", "error");
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <div className="p-6 w-full max-w-3xl mx-auto bg-gray-50 dark:bg-secondaryDark  shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800  dark:text-dark"> Create Quotations</h2>

      {successMessage && (
        <div className="mb-4 text-green-600 font-semibold bg-green-100 p-2 rounded-lg">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 text-red-600 font-semibold bg-red-100 p-2 rounded-lg">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSendQuote}>
        {/* Customer Selection */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2 dark:text-dark">Customer</label>
          <Select
            options={customers}
            onChange={handleCustomer}
            className="react-select"
            classNamePrefix="select"
            isClearable
          />
        </div>
        <input
          type="text"
          value={quotationName}
          onChange={(e) => setQuotationName(e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded-md mb-3 focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Quotation Title"
          required
        />

        {/* Quotation Items */}
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-2">Quotation Items</h3>
          {rows.map((row, index) => (
            <div key={index} className="flex flex-row mb-3">
              <div className="flex flex-col w-full p-4 bg-gray-50 shadow-md rounded-lg border border-gray-200 dark:bg-secondaryDark ">
                {/* Description */}
                <input
                  type="text"
                  value={row.description}
                  onChange={(e) => handleRowChange(index, 'description', e.target.value)}
                  className="flex-grow p-2 border border-gray-300 rounded-md mb-3 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Sub Title"
                  required
                />

                {/* Quantity and Amount */}
                <input
                  type="number"
                  value={row.quantity}
                  onChange={(e) => handleRowChange(index, 'quantity', e.target.value)}
                  className="flex-1 p-2 border border-gray-300 mb-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Quantity"
                  required
                />
                <input
                  type="number"
                  value={row.amount}
                  onChange={(e) => handleRowChange(index, 'amount', e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded-md mb-3 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Amount"
                  required
                />

                {/* Discount and Total */}
                <input
                  type="number"
                  value={row.discount}
                  onChange={(e) => handleRowChange(index, 'discount', e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded-md mb-3 focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Discount (%)"
                />
                <input
                  type="text"
                  value={row.totalAmount}
                  readOnly
                  className="flex-1 p-2 border border-gray-300 rounded-md mb-3 bg-gray-100 focus:outline-none"
                  placeholder="Total Amount"
                />

                {/* Delete Button */}
                <button
                  type="button"
                  onClick={() => deleteRow(index)}
                  className="self-start px-4 py-2 mt-2 text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors"
                >
                  Remove Row
                </button>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={addRow}
            className="text-blue-600 hover:underline"
          >
            Add Row
          </button>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          className={`mt-4 w-full p-3 text-white ${loading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'} rounded-md transition-colors`}
        >
          {loading ? 'Creating...' : 'Create Quotation'}
        </button>
      </form>
    </div>
  )
}

export default QuotationsInvoicing;
