import PropTypes from "prop-types";
import React, { useState } from 'react';
import "./JoinUs.css";
import GetQuoteModal from './GetQuoteModal';

const JoinUs = ({ className = "" }) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
    };

    return (
        <section className={`dream-container-wrapper ${className}`}>
            <div className="dream-container">
                <div className="slogan-wrapper">
                    <div className="dream-heading-wrapper">
                        <div className="dream-heading">
                            <h1 className="lets-join-with">Let’s Join With Us</h1>
                            <img className="dream-heading-child" alt="" src="images/line-26.svg" />
                        </div>
                    </div>
                    <h1 className="to-make-your">To Make Your Dream Come True</h1>
                </div>
                <button onClick={openModal} className="button6 mt-3">Get quote
                    {/* <b className="get-quote2"></b> */}
                    <img className="icon-relume18" alt="" src="/images/icon--relume-24.svg" />
                </button>
                {/* Get Quote Modal */}
                <GetQuoteModal open={showModal} onCloseQuote={closeModal} />
            </div>
        </section>
    );
};

JoinUs.propTypes = {
    className: PropTypes.string,
};

export default JoinUs;
