import React, { useState } from 'react';

export default function Profile() {

  const [selectedPage, setSelectedPage] = useState('personal');
  const [employeeData, setEmployeeData] = useState({
    firstName: 'abhay',
    middleName: '',
    lastName: 'deshmukh',
    employeeId: '0455',
    otherId: '',
    driverLicense: '',
    licenseExpiry: '',
    nationality: '',
    maritalStatus: '',
    dateOfBirth: '',
    gender: '',
    attachment: null,
  });

  const [contactData, setContactData] = useState({
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    homePhone: '',
    mobilePhone: '',
    workPhone: '',
    workEmail: '',
    otherEmail: '',
  });

  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [newEmergencyContact, setNewEmergencyContact] = useState({
    name: '',
    relationship: '',
    homeTelephone: '',
    mobile: '',
    workTelephone: ''
  });

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEmployeeData(prev => ({ ...prev, attachment: file }));
  };

  const handleAddEmergencyContact = (e) => {
    e.preventDefault();
    setEmergencyContacts(prev => [...prev, newEmergencyContact]);
    setNewEmergencyContact({
      name: '',
      relationship: '',
      homeTelephone: '',
      mobile: '',
      workTelephone: ''
    });
  };

  // Work Experiences
  const [workExperiences, setWorkExperiences] = useState([]);
  const [newWorkExperience, setNewWorkExperience] = useState({
    company: '',
    jobTitle: '',
    fromDate: '',
    toDate: '',
    comment: ''
  });

  // Handle input change for new work experience
  const handleExperienceInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorkExperience((prev) => ({ ...prev, [name]: value }));
  };

  // Add new work experience to the list
  const handleAddExperience = (e) => {  
    e.preventDefault();
    setWorkExperiences((prev) => [...prev, newWorkExperience]);
    setNewWorkExperience({ company: '', jobTitle: '', fromDate: '', toDate: '', comment: '' });
  };

  

  return (
    <div className="flex min-h-screen bg-gray-100 ml-4 mt-16">
      <aside className="w-64 bg-white p-6 shadow-md">
        <div className="mb-6">
          <div className="w-32 h-32 rounded-full bg-gray-300 mx-auto mb-4"></div>
          <h2 className="text-xl font-semibold text-center text-gray-700">Personal Details</h2>
        </div>
        <nav>
          <ul className="space-y-2">
            {['personal', 'contact', 'emergency', 'qualifications'].map((page) => (
              <li key={page}>
                <button
                  onClick={() => setSelectedPage(page)}
                  className={`block py-2 px-4 rounded w-full text-left ${
                    selectedPage === page ? 'text-blue-600 bg-blue-100' : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  {page.charAt(0).toUpperCase() + page.slice(1)} Details
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </aside>

      <main className="flex-1 p-8">
        {selectedPage === 'personal' && (
          <div>
            <h1 className="text-2xl font-bold mb-6 text-gray-800">Personal Details</h1>
            <form className="bg-white shadow-md rounded p-2 px-8 pt-6 pb-8 mb-4">
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Employee Full Name*</label>
                <div className="grid grid-cols-3 gap-4">
                  <input className=" border rounded w-full py-2 px-3" name="firstName" type="text" placeholder="First Name" value={employeeData.firstName} onChange={(e) => handleInputChange(e, setEmployeeData)} />
                  <input className=" border rounded w-full py-2 px-3" name="middleName" type="text" placeholder="Middle Name" value={employeeData.middleName} onChange={(e) => handleInputChange(e, setEmployeeData)} />
                  <input className=" border rounded w-full py-2 px-3" name="lastName" type="text" placeholder="Last Name" value={employeeData.lastName} onChange={(e) => handleInputChange(e, setEmployeeData)} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nationality">
                    Nationality
                  </label>
                  <select
                    className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="nationality"
                    name="nationality"
                    value={employeeData.nationality}
                    onChange={(e) => handleInputChange(e, setEmployeeData)}
                  >
                    <option value="">-- Select --</option>
                    <option value="US">United States</option>
                    <option value="UK">United Kingdom</option>
                    <option value="IN">India</option>
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="maritalStatus">
                    Marital Status
                  </label>
                  <select
                    className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="maritalStatus"
                    name="maritalStatus"
                    value={employeeData.maritalStatus}
                    onChange={(e) => handleInputChange(e, setEmployeeData)}
                  >
                    <option value="">-- Select --</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateOfBirth">
                    Date of Birth
                  </label>
                  <input
                    className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    value={employeeData.dateOfBirth}
                    onChange={(e) => handleInputChange(e, setEmployeeData)}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Gender
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="gender"
                        value="male"
                        checked={employeeData.gender === 'male'}
                        onChange={(e) => handleInputChange(e, setEmployeeData)}
                      />
                      <span className="ml-2">Male</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="gender"
                        value="female"
                        checked={employeeData.gender === 'female'}
                        onChange={(e) => handleInputChange(e, setEmployeeData)}
                      />
                      <span className="ml-2">Female</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="attachment">
                  Attach File
                </label>
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  onChange={handleFileChange}
                  className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {employeeData.attachment && (
                  <p className="mt-2 text-sm text-gray-500">Attached file: {employeeData.attachment.name}</p>
                )}
              </div>
              <div className="flex items-center justify-end">
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}

        {selectedPage === 'contact' && (
          <div>
            <h1 className="text-2xl font-bold mb-6 text-gray-800">Contact Details</h1>
            <form className="bg-white shadow-md rounded p-2 px-8 pt-6 pb-8 mb-4">
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-600">Address</h2>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Street 1
                    </label>
                    <input
                      type="text"
                      name="street1"
                      value={contactData.street1}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Street 2
                    </label>
                    <input
                      type="text"
                      name="street2"
                      value={contactData.street2}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={contactData.city}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      State/Province
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={contactData.state}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Zip/Postal Code
                    </label>
                    <input
                      type="text"
                      name="zipCode"
                      value={contactData.zipCode}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div className="col-span-1">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Country
                    </label>
                    <select
                      name="country"
                      value={contactData.country}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    >
                      <option value="">-- Select --</option>
                      <option value="US">United States</option>
                      <option value="UK">United Kingdom</option>
                      <option value="CA">Canada</option>
                      <option value="AU">Australia</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="space-y-4 mt-6">
                <h2 className="text-lg font-medium text-gray-600">Telephone</h2>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Home
                    </label>
                    <input
                      type="tel"
                      name="homePhone"
                      value={contactData.homePhone}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Mobile
                    </label>
                    <input
                      type="tel"
                      name="mobilePhone"
                      value={contactData.mobilePhone}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Work
                    </label>
                    <input
                      type="tel"
                      name="workPhone"
                      value={contactData.workPhone}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-4 mt-6">
                <h2 className="text-lg font-medium text-gray-600">Email</h2>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Work Email
                    </label>
                    <input
                      type="email"
                      name="workEmail"
                      value={contactData.workEmail}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Other Email
                    </label>
                    <input
                      type="email"
                      name="otherEmail"
                      value={contactData.otherEmail}
                      onChange={(e) => handleInputChange(e, setContactData)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6 mt-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="attachment">
                  Attach File
                </label>
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  onChange={handleFileChange}
                  className=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {employeeData.attachment && (
                  <p className="mt-2 text-sm text-gray-500">Attached file: {employeeData.attachment.name}</p>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}

{selectedPage === 'emergency' && (
          <div>
            <h1 className="text-2xl font-bold mb-6 text-gray-800">Emergency Contacts</h1>
            <form onSubmit={handleAddEmergencyContact} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Name*</label>
                <input 
                  className=" border rounded w-full py-2 px-3" 
                  name="name" 
                  type="text" 
                  placeholder="Full Name" 
                  value={newEmergencyContact.name} 
                  onChange={(e) => handleInputChange(e, setNewEmergencyContact)}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">Relationship*</label>
                <input 
                  className=" border rounded w-full py-2 px-3" 
                  name="relationship" 
                  type="text" 
                  placeholder="Relationship" 
                  value={newEmergencyContact.relationship} 
                  onChange={(e) => handleInputChange(e, setNewEmergencyContact)}
                  required
                />
              </div>
              <div className="grid grid-cols-3 gap-4 mb-6">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Home Phone</label>
                  <input 
                    className=" border rounded w-full py-2 px-3" 
                    name="homePhone" 
                    type="tel" 
                    placeholder="Home Phone" 
                    value={newEmergencyContact.homePhone} 
                    onChange={(e) => handleInputChange(e, setNewEmergencyContact)}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Mobile Phone</label>
                  <input 
                    className=" border rounded w-full py-2 px-3" 
                    name="mobilePhone" 
                    type="tel" 
                    placeholder="Mobile Phone" 
                    value={newEmergencyContact.mobilePhone} 
                    onChange={(e) => handleInputChange(e, setNewEmergencyContact)}
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">Work Phone</label>
                  <input 
                    className=" border rounded w-full py-2 px-3" 
                    name="workPhone" 
                    type="tel" 
                    placeholder="Work Phone" 
                    value={newEmergencyContact.workPhone} 
                    onChange={(e) => handleInputChange(e, setNewEmergencyContact)}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end">
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Add Contact
                </button>
              </div>
            </form>
          </div>
        )}

        {selectedPage === 'qualifications' && (
          <div>
            <h1 className=" text-2xl font-bold mb-6 text-gray-800">Qualifications</h1>

            <div className="space-y-6">
              <h3 className="text-lg font-medium text-gray-700">Add Work Experience</h3>
              <form onSubmit={handleAddExperience} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                      Company<span className="text-red-500">*</span>
                    </label>
                    <input
                      id="company"
                      name="company"
                      type="text"
                      value={newWorkExperience.company}
                      onChange={handleExperienceInputChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">
                      Job Title<span className="text-red-500">*</span>
                    </label>
                    <input
                      id="jobTitle"
                      name="jobTitle"
                      type="text"
                      value={newWorkExperience.jobTitle}
                      onChange={handleExperienceInputChange}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="fromDate" className="block text-sm font-medium text-gray-700">From</label>
                    <input
                      id="fromDate"
                      name="fromDate"
                      type="date"
                      value={newWorkExperience.fromDate}
                      onChange={handleExperienceInputChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="toDate" className="block text-sm font-medium text-gray-700">To</label>
                    <input
                      id="toDate"
                      name="toDate"
                      type="date"
                      value={newWorkExperience.toDate}
                      onChange={handleExperienceInputChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div className="space-y-2 md:col-span-2">
                    <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
                    <textarea
                      id="comment"
                      name="comment"
                      value={newWorkExperience.comment}
                      onChange={handleExperienceInputChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 min-h-[100px]"
                    />
                  </div>
                </div>

                <div className="flex justify-end gap-4 mt-4">
                  <button type="button" className="px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none">
                    Cancel
                  </button>
                  <button type="submit" className="px-4 py-2 text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none">
                    Save
                  </button>
                </div>

                <div className="p-6">
                  <h2 className="text-2xl font-semibold mb-4">Add Education</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label htmlFor="level" className="block text-sm font-medium text-gray-700">
                        Level*
                      </label>
                      <select
                        id="level"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">-- Select --</option>
                        <option value="high-school">High School</option>
                        <option value="bachelor">Bachelor's</option>
                        <option value="master">Master's</option>
                        <option value="phd">Ph.D.</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="institute" className="block text-sm font-medium text-gray-700">
                        Institute
                      </label>
                      <input
                        type="text"
                        id="institute"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter institute name"
                      />
                    </div>

                    <div className="space-y-2">
                      <label htmlFor="major" className="block text-sm font-medium text-gray-700">
                        Major/Specialization
                      </label>
                      <input
                        type="text"
                        id="major"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter major or specialization"
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="year" className="block text-sm font-medium text-gray-700">
                        Year
                      </label>
                      <input
                        type="text"
                        id="year"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter year"
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="gpa" className="block text-sm font-medium text-gray-700">
                        GPA/Score
                      </label>
                      <input
                        type="text"
                        id="gpa"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter GPA or score"
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="startDate"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                
                    <div className="flex justify-end gap-4 mt-6">
                      <button type="button" className="px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none">
                        Cancel
                      </button>
                      <button type="submit" className="px-4 py-2 text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none">
                        Save
                      </button>
                    </div>
              
              </div>
              </div>
            </form>

          </div>
        </div>
        )}
      </main>
    </div>
  );
}