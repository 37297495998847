import React, { useState } from 'react';
import axiosInstance from '../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setUsername('');
        setPassword('');

        const userData = { username, password };

        try {
            const response = await axiosInstance.post('/backend/api/auth/login', userData);

            if (response.data) {
                const token = response.data.jwtToken;

                // console.log(token);

                // Decode the JWT to inspect the structure
                const decodedToken = jwtDecode(token);
                // console.log('Decoded Token:', decodedToken); // Log the full decoded token

                // Handle roles as a single string
                const role = response.data.roles.replace('ROLE_', '').toLowerCase();

                // console.log('Role:', role); // Verify role is logged correctly

                // Store token and role in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('userRole', role);
                localStorage.setItem('email', response.data.email);

                if (role === 'customer') {
                    localStorage.setItem('customerId', response.data.customerId); // Store customerId
                } else if (role === 'employee') {
                    localStorage.setItem('employeeId', response.data.employeeId); // Store employeeId
                }

                setErrorMessage('');
                setSuccessMessage('Login successful!');
                setTimeout(() => {
                    if (role === 'admin') {
                        navigate('/admin/dashboard');
                    } else if (role === 'customer') {
                        navigate('/customer/client-portal');
                    } else if (role === 'employee') {
                        navigate("/employee/HRM-Attendence")
                    }
                }, 2000);
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Login failed, please try again.');
        } finally {
            setLoading(false);
        }

        setUsername('');
        setPassword('');
    };






    return (
        <div className="container-fluid d-flex align-items-center justify-content-center pb-3"
            style={{
                backgroundImage: 'url(.././images/loginkala-bg.jpeg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh'
            }}>
            <div style={{ width: '400px' }}>
                <div className="card shadow-lg bg-white text-black align-items-center mt-3">
                    <div style={{ width: '-webkit-fill-available' }}>
                        <h3 className="card-title text-center mb-2 fw-bold fs-4">
                            LOGIN
                        </h3>
                        <p className="text-muted text-center fs-6">
                            Sign into your account
                        </p>
                        {errorMessage && <div className="alert alert-danger fs-6 mt-2">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success fs-6 mt-2">{successMessage}</div>}

                        <form onSubmit={handleSubmit}>
                            <div className="mb-2">
                                <label htmlFor="username" className="fs-6 fw-bold form-label">Username or email address</label>
                                <input
                                    id="username"
                                    type="text"
                                    className="form-control"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="mb-2">
                                <label htmlFor="password" className="fs-6 fw-bold form-label">Password</label>
                                <input
                                    id="password"
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="d-grid gap-2">

                                <button disabled={loading} type="submit" className="text-white  bg-dark w-full  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg  px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center justify-center text-sm ">
                                    {loading ? <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg> : ""}
                                    {loading ? "Loading..." : "Login"}
                                </button>
                            </div>
                        </form>

                        <div className="text-center mt-3">
                            <p className="text-muted fs-6">
                                <button onClick={() => navigate('/forget-password')} className="btn btn-link p-0">
                                    Forgot Password?
                                </button>
                            </p>
                            <p className="text-muted fs-6 "> <Link to="/" className='btn btn-link p-0' >Back to home</Link>  </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
