import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

const AddStock = ({addStock}) => {
    const [newStock, setNewStock] = useState({
        name: "",
        sku: "",
        quantity: "",
        location: "",
        minLevel: "",
        maxLevel: "",
        reason: "Purchase"
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validateStock = () => {
        const { quantity, minLevel, maxLevel } = newStock;
        if (parseInt(quantity) < 0 || parseInt(minLevel) < 0 || parseInt(maxLevel) < 0) {
            setErrorMessage("Stock levels and quantity cannot be negative.");
            return false;
        }
        if (parseInt(minLevel) > parseInt(maxLevel)) {
            setErrorMessage("Minimum stock level cannot exceed maximum stock level.");
            return false;
        }
        return true;
    };

    const handleNewStock = async (e) => {
        e.preventDefault();
        if (!validateStock()) return;

        setIsSubmitting(true);
        setErrorMessage("");
        setSuccessMessage("");

        const stockData = {
            ...newStock,
            quantity: parseInt(newStock.quantity),
            minLevel: parseInt(newStock.minLevel),
            maxLevel: parseInt(newStock.maxLevel),
        };

        try {
            const response = await axiosInstance.post('/backend/api/products', {
                name: newStock.name,
                // category:
                quantity: newStock.quantity,
                skuNumber: newStock.sku,
                stockLocation: newStock.location,
                minStockLevel: newStock.minLevel,
                maxStockLevel: newStock.maxLevel,
                reorderPoint: newStock.reason
            });
            if (response.status === 201) {
                addStock(response.data);
                setSuccessMessage("Stock added successfully.");
                setNewStock({
                    name: "",
                    sku: "",
                    quantity: "",
                    location: "",
                    minLevel: "",
                    maxLevel: "",
                    reason: "Purchase"
                });
            } else {
                setErrorMessage("Failed to add stock. Please try again.");
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "An error occurred while adding stock.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="bg-gray-50 dark:bg-secondaryDark p-3 rounded-lg w-full max-w-md">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-dark">Add New Stock</h2>
            {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
            {successMessage && <div className="text-green-500 mb-4">{successMessage}</div>}
            <form onSubmit={handleNewStock} className="space-y-4  text-gray-700 dark:text-dark ">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium ">
                        Item Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        value={newStock.name}
                        onChange={(e) => setNewStock({ ...newStock, name: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="sku" className="block text-sm font-medium ">
                        SKU
                    </label>
                    <input
                        type="text"
                        id="sku"
                        value={newStock.sku}
                        onChange={(e) => setNewStock({ ...newStock, sku: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="newQuantity" className="block text-sm font-medium ">
                        Quantity
                    </label>
                    <input
                        type="number"
                        id="newQuantity"
                        value={newStock.quantity}
                        onChange={(e) => setNewStock({ ...newStock, quantity: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="newLocation" className="block text-sm font-medium ">
                        Stock Location
                    </label>
                    <input
                        type="text"
                        id="newLocation"
                        value={newStock.location}
                        onChange={(e) => setNewStock({ ...newStock, location: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="minLevel" className="block text-sm font-medium ">
                        Minimum Stock Level
                    </label>
                    <input
                        type="number"
                        id="minLevel"
                        value={newStock.minLevel}
                        onChange={(e) => setNewStock({ ...newStock, minLevel: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="maxLevel" className="block text-sm font-medium ">
                        Maximum Stock Level
                    </label>
                    <input
                        type="number"
                        id="maxLevel"
                        value={newStock.maxLevel}
                        onChange={(e) => setNewStock({ ...newStock, maxLevel: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="newReason" className="block text-sm font-medium ">
                        Reason for Movement
                    </label>
                    <select
                        id="newReason"
                        value={newStock.reason}
                        onChange={(e) => setNewStock({ ...newStock, reason: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="Purchase">Purchase</option>
                        <option value="Transfer">Transfer</option>
                        <option value="Adjustment">Adjustment</option>
                    </select>
                </div>
                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        onClick={() => setIsNewStockModalOpen(false)}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-indigo-600 text-white rounded-md dark:bg-dark dark:hover:bg-primaryDark"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddStock;
