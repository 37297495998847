import React from 'react';
import LowStockTable from './LowStockTable';
import RestockTable from './RestockTable';

const RecordAlert = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-gray-800">Reorder Alerts</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Low Stock Products</h2>
        <LowStockTable />
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Upcoming Restocks</h2>
        <RestockTable />
      </div>
    </div>
  )
}

export default RecordAlert

