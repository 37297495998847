import { useEffect, useState } from "react";
import AttendanceManagement from "./AttendenceManagement";
import { LeaveRequestForm } from "./LeaveRequestForm";
import axiosInstance from "../../services/AxiosInstance";
import AdminAttendenceManagement from "./AdminAttendenceManagement";
import AdminLeaveStatus from "./AdminLeaveStatus";

const HrmAttendenceLayout = () => {
    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [role, setRole] = useState('');
    const [adminView, setAdminView] = useState("dashboard");
    const [attendence, setAttendence] = useState([]);
    const [attendenceLoading, setAttendenceLoading] = useState(false);
    const [attendenceError, setAttendenceError] = useState('');

    const fetchAttendence = async () => {
        setAttendenceLoading(true);
        try {
            const response = await axiosInstance.get(`backend/api/employees`);

            if (response.status === 200 && response.data) {
                console.log("Attendance Data Fetched:", response.data);  // Log fetched data
                // Check if data is an array or object and if it matches the expected structure
                if (Array.isArray(response.data)) {
                    setAttendence(response.data);
                    // console.log("Attendance State Updated:", response.data);
                } else {
                    console.error("Unexpected data format:", response.data);
                    setAttendenceError("Unexpected data format.");
                }
            } else {
                setAttendenceError("Failed to fetch attendance record.");
            }
        } catch (error) {
            setAttendenceError("Failed to fetch attendance record.");
        } finally {
            setAttendenceLoading(false);
        }
    };

    const fetchEmployee = async () => {
        setLoading(true);
        setError('');
        const email = localStorage.getItem("email");

        try {
            const response = await axiosInstance.get(`/backend/api/employees/email/${email}`);
            setEmployee(response.data);
        } catch (err) {
            setError('Failed to fetch employee data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const userRole = localStorage.getItem("userRole");
        setRole(userRole);

        if (userRole === "employee") {
            fetchEmployee();
        } else if (userRole === "admin") {
            fetchAttendence();
        }
    }, []);


    const handleLeave = (leave) => {
        if (!employee) return;

        const updatedEmployee = {
            ...employee,
            leaveRequests: [...(employee.leaveRequests || []), leave],
        };

        setEmployee(updatedEmployee);
    };

    const addCheckIn = async (checkIn) => {
        if (!employee) return;

        const updatedEmployee = {
            ...employee,
            attendanceRecords: [...(employee.attendanceRecords || []), checkIn],
        };

        setEmployee(updatedEmployee);
    };

    return (
        <div className="flex flex-col items-center mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark overflow-y-scroll">
            {/* Show Admin Buttons if Role is Admin */}
            {role === "admin" && (
                <div className="flex gap-4 mb-2">
                    <button
                        onClick={() => setAdminView("dashboard")}
                        className={`px-4 py-2 rounded-lg ${adminView === "dashboard" ? "bg-blue-700 text-white" : "bg-blue-600 text-white hover:bg-blue-700"}`}
                    >
                        Attendance Dashboard
                    </button>
                    <button
                        onClick={() => setAdminView("leave")}
                        className={`px-4 py-2 rounded-lg ${adminView === "leave" ? "bg-blue-700 text-white" : "bg-blue-600 text-white hover:bg-blue-700"}`}
                    >
                        Leave Status
                    </button>
                </div>
            )}

            {/* Show Admin Layout Based on Selected View */}
            {role === "admin" && adminView === "dashboard" && (
                <div className="w-full max-w-8xl">
                    {/* Admin's Attendance Dashboard Component */}
                    <AdminAttendenceManagement employees={attendence} error={attendenceError} loading={attendenceLoading} />
                </div>
            )}

            {role === "admin" && adminView === "leave" && (
                <div className="w-full max-w-8xl">
                    {/* Admin's Leave Status Component */}
                    <AdminLeaveStatus />
                </div>
            )}

            {/* Show Layout Only if Role is Employee */}
            {role === "employee" && (
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 w-full max-w-8xl">
                    {/* <LeaveRequestForm employee={employee} loading={loading} error={error} submittedLeave={handleLeave} /> */}

                    <div className="lg:col-span-4 grid grid-cols-1  gap-8 h-[calc(100vh-4rem)] w-full overflow-y-auto">
                        <AttendanceManagement employee={employee} loading={loading} error={error} handleCheckIn={addCheckIn} setEmployee={setEmployee} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default HrmAttendenceLayout;
