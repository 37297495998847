import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';

export function LeaveRequestForm({ employee, error, loading, submittedLeave, isOpen, onClose }) {
  const [leaveType, setLeaveType] = useState('');
  const [leaveStartDate, setLeaveStartDate] = useState('');
  const [leaveEndDate, setLeaveEndDate] = useState('');
  const [leaveReason, setLeaveReason] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  const handleLeaveRequestSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);

    const leaveRequestData = {
      leaveType,
      startDate: leaveStartDate,
      endDate: leaveEndDate,
      leaveReason,
    };

    try {
      const response = await axiosInstance.post(`backend/api/leaves/${employee.employeeId}`, leaveRequestData);
      if (response.status === 201) {
        submittedLeave(response.data);
        setSubmissionStatus('Leave request submitted successfully!');
        setTimeout(() => {
          onClose();
        }, 2000);
        // Clear form fields after successful submission
        setLeaveType('');
        setLeaveStartDate('');
        setLeaveEndDate('');
        setLeaveReason('');
      }
    } catch (error) {
      setSubmissionStatus('Failed to submit leave request. Please try again.');
      console.error('Error submitting leave request:', error);
    } finally {
      setFormLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mx-auto relative">
        <button onClick={onClose} className="absolute text-3xl top-2 right-2 text-red-500 hover:text-gray-700">
          &times;
        </button>

        <h2 className="text-xl font-semibold mb-4 text-center">Leave Request Form</h2>

        {loading && <p className="text-center text-blue-500">Loading...</p>}
        {error && <p className="text-center text-red-500">Error: {error}</p>}
        {submissionStatus && (
          <p className={`text-center ${submissionStatus.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>
            {submissionStatus}
          </p>
        )}

        <form onSubmit={handleLeaveRequestSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-600 font-medium mb-2">Leave Type</label>
            <select
              value={leaveType}
              onChange={(e) => setLeaveType(e.target.value)}
              className="block w-full p-2 border rounded-md bg-white"
              required
            >
              <option value="">Select Leave Type</option>
              <option value="Annual Leave">Annual Leave</option>
              <option value="Sick Leave">Sick Leave</option>
              <option value="Personal Leave">Personal Leave</option>
              <option value="Public Holidays">Public Holidays</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Leave Start Date</label>
            <input
              type="date"
              value={leaveStartDate}
              onChange={(e) => setLeaveStartDate(e.target.value)}
              className="block w-full p-2 border rounded-md bg-white"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Leave End Date</label>
            <input
              type="date"
              value={leaveEndDate}
              min={leaveStartDate}
              onChange={(e) => setLeaveEndDate(e.target.value)}
              className="block w-full p-2 border rounded-md bg-white"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Leave Reason</label>
            <textarea
              value={leaveReason}
              onChange={(e) => setLeaveReason(e.target.value)}
              className="block w-full p-2 border rounded-md bg-white h-28 resize-none"
              placeholder="Enter your reason for leave..."
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-md"
            disabled={formLoading}
          >
            {formLoading ? "Loading..." : "Submit Leave Request"}
          </button>
        </form>
      </div>
    </div>
  );
}
