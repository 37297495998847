import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { LeaveRequestForm } from './LeaveRequestForm';

export function AttendanceDashboard({ employee, error, loading, addCheckIn, setEmployee }) {
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [attendanceId, setAttendanceId] = useState(null);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

  useEffect(() => {
    if (employee && employee.attendanceRecords) {
      const today = new Date().toLocaleDateString();

      const todayRecord = employee.attendanceRecords.find(record => {
        const recordDate = new Date(record.checkInTime).toLocaleDateString();
        return recordDate === today;
      });

      if (todayRecord) {
        setCheckInTime(new Date(todayRecord.checkInTime).toLocaleTimeString());
        setCheckOutTime(todayRecord.checkOutTime ? new Date(todayRecord.checkOutTime).toLocaleTimeString() : null);
        setIsCheckedIn(true);
        setAttendanceId(todayRecord.attendanceId);

        if (todayRecord.checkOutTime) {
          setIsCheckedOut(true);
        }
      }
    }
  }, [employee]);

  const handleCheckIn = async () => {
    const checkIn = new Date().toLocaleTimeString();
    setCheckInTime(checkIn);
    setIsCheckedIn(true);

    try {
      const response = await axiosInstance.post(`/backend/api/attendance/checkin/${employee.employeeId}`, {
        checkInTime: checkIn,
      });
      addCheckIn(response.data);
      console.log("Check-in time recorded successfully.");
    } catch (error) {
      console.error("Failed to record check-in time:", error);
    }
  };

  const handleCheckOut = async () => {
    const checkOut = new Date().toLocaleTimeString();
    setCheckOutTime(checkOut);
    setIsCheckedOut(true);

    try {
      await axiosInstance.post(`/backend/api/attendance/checkout/${attendanceId}`, {
        checkOutTime: checkOut,
      });
      console.log("Check-out time recorded successfully.");
    } catch (error) {
      console.error("Failed to record check-out time:", error);
      setIsCheckedOut(false);
    }
  };

  const handleLeave = (leave) => {
    if (!employee) return;

    const updatedEmployee = {
      ...employee,
      leaveRequests: [...(employee.leaveRequests || []), leave],
    };

    setEmployee(updatedEmployee);
  };

  return (
    <div className="mx-auto bg-white p-4 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      {loading && <p className="text-center text-blue-500">Loading...</p>}
      {error && <p className="text-center text-red-500">Error: {error}</p>}

      {!loading && !error && (
        <>
          <div className="mb-2 text-center">
            <h2 className="text-2xl font-bold text-blue-700 dark:text-blue-300">Attendance Dashboard</h2>
          </div>

          <div className="flex justify-center space-x-4 mb-6">
            <button
              onClick={handleCheckIn}
              disabled={isCheckedIn}
              className={`${isCheckedIn ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
                } text-white font-bold py-2 px-6 rounded-full shadow-md transition duration-200 ease-in-out`}
            >
              {isCheckedIn ? "Checked-in" : "Check-in"}
            </button>
            <button
              onClick={handleCheckOut}
              disabled={!isCheckedIn || isCheckedOut}
              className={`${isCheckedOut ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'
                } text-white font-bold py-2 px-6 rounded-full shadow-md transition duration-200 ease-in-out`}
            >
              {isCheckedOut ? "Checked-out" : "Check-out"}
            </button>

            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full shadow-md transition duration-200 ease-in-out"
              onClick={() => setIsLeaveModalOpen(true)}
            >
              Open Leave Request
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg shadow-md dark:bg-gray-900">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm dark:bg-gray-700 dark:text-gray-300">
                  <th className="p-4">Employee ID</th>
                  <th className="p-4 text-nowrap">Employee Name</th>
                  <th className="p-4 text-nowrap">Date</th>
                  <th className="p-4 text-nowrap">Check-in</th>
                  <th className="p-4 text-nowrap">Check-out</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-t dark:border-gray-700">
                  <td className="p-4">{employee?.employeeId || 'N/A'}</td>
                  <td className="p-4 text-nowrap">{employee?.fullName || 'N/A'}</td>
                  <td className="p-4 text-nowrap">{new Date().toLocaleDateString()}</td>
                  <td className="p-4 text-nowrap">{checkInTime || 'Not checked in'}</td>
                  <td className="p-4 text-nowrap">{checkOutTime || 'Not checked out'}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {isLeaveModalOpen && (
            <LeaveRequestForm
              employee={employee}
              error={error}
              loading={loading}
              submittedLeave={handleLeave}
              isOpen={isLeaveModalOpen}
              onClose={() => setIsLeaveModalOpen(false)}
            />
          )}
        </>
      )}
    </div>
  );
}
