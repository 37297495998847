import React from 'react';
// import PerformanceDashboard from './PerformanceDashboard';
// import PerformanceReview from './PerformanceReview';
import PerformanceGoals from './PerformanceGoals';

const PerformanceManagement = () => {
  const handleReviewSubmit = (data) => {
    console.log("Submitted Review Data: ", data);
    // Handle the submitted review data here (e.g., send it to a server)
  };

  return (
    <div className="container mx-auto p-3 space-y-8  rounded-lg shadow-md ">
      <div className="bg-gray-50 p-4 rounded-lg shadow mb-4 dark:bg-secondaryDark">
        <h1 className="text-3xl font-bold text-gray-800 dark:text-dark ">Performance Management</h1>
      </div>
      {/* <PerformanceDashboard /> */}
      {/* <PerformanceReview onSubmit={handleReviewSubmit} /> */}
      <PerformanceGoals />
    </div>
  );
};

export default PerformanceManagement;
