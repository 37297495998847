import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';
import Select from "react-select";
import { Loader } from 'lucide-react';

const CreateAdminModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [adminType, setAdminType] = useState('admin');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await axiosInstance.get(`backend/api/employees`);
                if (response.status === 200) {
                    setEmployee(response.data.map(emp => ({
                        label: emp.fullName,
                        value: emp.employeeId,
                        email: emp.email // Include the email in the options
                    })));
                }
            } catch (error) {
                console.error('Failed to fetch employees', error);
            }
        };

        fetchEmployee();
    }, []);

    const handleSelectChange = (selectedOption) => {
        setSelectedEmployee(selectedOption.value); // Set the value of the selected employee
        setEmail(selectedOption.email); // Set the email of the selected employee
    };

    const handleAdminTypeChange = (type) => {
        setAdminType(type);
        setSelectedEmployee(''); // Clear selected employee
        setEmail(''); // Reset email when changing to admin
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            return;
        }

        const userData = {
            username,
            email,
            password,
            role: adminType === 'admin' ? 'ADMIN' : 'EMPLOYEE',
            employeeId: adminType === 'employee' ? selectedEmployee : null,
        };

        try {
            const response = await axiosInstance.post('/backend/api/auth/regadminuser', userData);
            if (response.data) {
                setErrorMessage('');
                setSuccessMessage('Signup successful! Redirecting to login...');
                setTimeout(() => {
                    onClose();
                }, 2000);
            }
        } catch (error) {
            console.log(error.response);
            if (error.response?.status === 409) {
                setErrorMessage("Email or username already exists");
            } else {
                setErrorMessage(error.response?.data?.message || 'Signup failed, please try again.');
            }
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white dark:bg-secondaryDark p-6 rounded-lg shadow-lg max-w-md w-full">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute text-3xl text-red-700 top-3 right-3 hover:text-gray-800 dark:hover:text-gray-300 transition"
                    aria-label="Close"
                >
                    ×
                </button>

                {/* Form Title */}
                <h2 className="text-2xl font-semibold mb-4 text-center text-blue-700 dark:text-textDark">Create Admin</h2>

                <form onSubmit={handleSubmit}>
                    {/* Admin Type Selection */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2">Admin Type</label>
                        <div className="flex gap-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="adminType"
                                    value="admin"
                                    checked={adminType === 'admin'}
                                    onChange={() => handleAdminTypeChange('admin')}
                                    className="form-radio"
                                />
                                <span className="ml-2">Admin</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="adminType"
                                    value="employee"
                                    checked={adminType === 'employee'}
                                    onChange={() => handleAdminTypeChange('employee')}
                                    className="form-radio"
                                />
                                <span className="ml-2">Employee</span>
                            </label>
                        </div>
                    </div>

                    {/* Employee Selection Dropdown */}
                    {adminType === 'employee' && (
                        <div className="mb-4">
                            <label htmlFor="employeeSelect" className="block text-sm font-medium">Select Employee</label>
                            <Select
                                name='employee'
                                onChange={handleSelectChange}
                                options={employee}
                                placeholder="Select an employee..."
                            />
                        </div>
                    )}

                    {/* Username */}
                    <div className="mb-2">
                        <label htmlFor="username" className="block text-sm font-medium">Username</label>
                        <input
                            id="username"
                            type="text"
                            className="w-full px-4 py-2 border rounded-md dark:bg-gray-800 dark:border-gray-700"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>

                    {/* Email */}
                    <div className="mb-2">
                        <label htmlFor="email" className="block text-sm font-medium">Email</label>
                        <input
                            id="email"
                            type="email"
                            className="w-full px-4 py-2 border rounded-md dark:bg-gray-800 dark:border-gray-700"
                            value={email}
                            onChange={e => adminType === 'admin' ? setEmail(e.target.value) : null} // Update only if admin
                            required
                            readOnly={adminType === 'employee'} // Make readOnly when an employee is selected
                        />
                    </div>

                    {/* Password */}
                    <div className="mb-2">
                        <label htmlFor="password" className="block text-sm font-medium">Password</label>
                        <input
                            id="password"
                            type="password"
                            className="w-full px-4 py-2 border rounded-md dark:bg-gray-800 dark:border-gray-700"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>

                    {/* Confirm Password */}
                    <div className="mb-2">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium">Confirm Password</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            className="w-full px-4 py-2 border rounded-md dark:bg-gray-800 dark:border-gray-700"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="mb-3">
                        <div className="d-grid gap-2">
                           
                            <button disabled={loading} type="submit" className="text-white  bg-dark w-full  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg  px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center justify-center text-sm ">
                                {loading ? <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg> : ""}
                                {loading ? "Loading..." : "Create Admin"}
                            </button>
                        </div>
                    </div>

                    {/* Error and Success Messages */}
                    {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
                    {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}
                </form>
            </div>
        </div>
    );
};

export default CreateAdminModal;
