import { useEffect, useState } from "react";
import EmployeeCard from "./EmployeeCard"; // Importing the EmployeeCard component
import EmployeeEditModal from "./EmployeeEdit"; // Correct import for EmployeeEditModal
import EmployeeViewModal from "./EmployeeView"; // Importing the EmployeeViewModal component
import axiosInstance from "../../services/AxiosInstance";




export default function EmployeeManagement() {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")


  useEffect(() => {

    const fetchEmployee = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/backend/api/employees")

        // console.log(response.data);

        if (response.status === 200) {
          setEmployees(response.data)

        } else {
          setError("fail to Fetch employee")
        }
      } catch (error) {
        setError("fail to Fetch employee")
        console.error(error)
      } finally {
        setLoading(false)
      }


    }

    fetchEmployee();



  }, [])


  const filteredEmployees = employees.filter(
    (employee) =>
      (employee.fullName?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (employee.role?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
      (employee.status?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );
  const handleSaveEmployee = async (updatedEmployee) => {
    try {
      const employeeData = {
        fullName: updatedEmployee.name,
        email: updatedEmployee.email,
        phoneNumber: updatedEmployee.phone,
        dateOfJoining: updatedEmployee.joinDate,
        role: updatedEmployee.role,
        status: updatedEmployee.status,
      };

      let response;
      if (updatedEmployee.id) {
        // Update existing employee
        response = await axiosInstance.put(`/backend/api/employees/${updatedEmployee.id}`, employeeData);
      } else {
        // Add new employee
        response = await axiosInstance.post("/backend/api/employees", employeeData);
        // console.log(response.data)
      }

      if (response.status === 200 || response.status === 201) {
        setEmployees((prevEmployees) =>
          updatedEmployee.id
            ? prevEmployees.map((emp) =>
              emp.id === updatedEmployee.id
                ? { ...updatedEmployee, name: updatedEmployee.fullName || emp.name }
                : emp
            )
            : [...prevEmployees, { ...updatedEmployee, name: response.data.fullName, id: response.data.employeeId }]
        );
        setSelectedEmployee(null);

      } else {
        setError("Failed to save employee");
      }
    } catch (error) {
      setError("Error saving employee");
      console.error("Error saving employee:", error);
    }
  };


  const handleAddClick = () => {
    setSelectedEmployee({ name: "", role: "", department: "", status: "Active" }); // Set to new employee with default values
    setIsEditMode(true); // Set to edit mode for adding new employees
  };

  const handleEditClick = (employee) => {
    setSelectedEmployee(employee); // Set to the selected employee for edit
    setIsEditMode(true); // Set to edit mode for editing
  };

  const handleViewClick = (employee) => {
    setSelectedEmployee(employee);
    setIsEditMode(false);
  };

  return (
    <div className="container mx-auto p-4 space-y-8  rounded-lg shadow-md ">
      <div className="bg-gray-50 dark:bg-secondaryDark p-4 rounded-lg shadow mb-6">
        <div className="flex justify-between items-center">
          {/* Employee Management Text on the Left */}
          <h1 className="text-3xl font-bold text-gray-800">Employee Management</h1>

          {/* Search Bar on the Right */}
          <div className="relative w-full sm:w-64">
            <input
              type="text"
              placeholder="Search employees..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 border rounded-md p-2 w-full"
            />
            {/* <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">🔍</span> */}
          </div>
        </div>
      </div>
      {/* Button Container */}
      <div className="ml-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center dark:bg-primaryDark "
          onClick={handleAddClick} 
        >
          ➕ Add Employee
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {loading ? (
          <div className="fixed inset-0 flex justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-3">
              <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
              <p className="dark:text-secondaryDark">Loading Employees</p>
            </div>
          </div>
        ) : (
          filteredEmployees.map((employee) => (
            <EmployeeCard
              key={employee.employeeId}
              employee={employee}
              onView={() => handleViewClick(employee)}
              onEdit={() => handleEditClick(employee)}
            />
          ))
        )}
      </div>




      {/* Render modals based on the mode */}
      {
        selectedEmployee && (isEditMode ? (
          <EmployeeEditModal
            employee={selectedEmployee}
            onClose={() => setSelectedEmployee(null)} // Close modal function
            onSubmit={handleSaveEmployee}
            isEditMode={isEditMode} // Function to handle form submission
          />
        ) : (
          <EmployeeViewModal
            employee={selectedEmployee}
            onClose={() => setSelectedEmployee(null)}
          />
        ))
      }
    </div >

  );
}
