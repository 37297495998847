import React, { useState } from 'react';
import AddManPowers from './AddManPowers';
import EquipmentAllocation from './EquipmentAllocation';
import ManpowerAssessment from './ManpowerAssessment';
import EquipmentAllocationTable from './EquipmentAllocationTable';

const ResourceAllocationLayout = () => {
    const [activeTab, setActiveTab] = useState('manpower'); // Default to 'manpower' tab
    const [activeTable, setActiveTable] = useState("manpower")
    const [manpower, setManpower] = useState([]);
    const [equipment, setEquipment] = useState([])



    // add manpower to existing list
    const addManPower = (newManpower) => {
        setActiveTable("manpower")
        setManpower((prev) => [...prev, newManpower])
    }

    // add equipments to previous list
    const addEquipment = (newEquipment) => {
        setActiveTable('equipment')
        setEquipment((prev) => [...prev, newEquipment])
    }


    return (
        <div className="flex justify-center items-start mt-16 h-[calc(100vh-4rem)] bg-gray-100 dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full max-w-7xl ">

                {/* Left side - Tab buttons */}
                <div className="bg-gray-50 rounded-lg shadow-lg h-[calc(100vh-4rem)]  overflow-y-auto pt-2 pb-2 dark:bg-dark">
                    <div className="flex space-x-4 px-2 mb-2"> {/* Flex container for buttons in one line */}
                        <button
                            className={`flex-1 p-2 text-nowrap text-center text-sm rounded-lg ${activeTab === 'manpower' ? 'bg-blue-500 text-gray-50 dark:bg-secondaryDark  ' : 'bg-gray-200 text-gray-700'}`}
                            onClick={() => setActiveTab('manpower')}
                        >
                            Add Manpower
                        </button>
                        <button
                            className={`flex-1 p-2 text-center text-nowrap text-sm rounded-lg ${activeTab === 'equipment' ? 'bg-blue-500 text-gray-50 dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                            onClick={() => setActiveTab('equipment')}
                        >
                            Equipment Allocation
                        </button>
                    </div>

                    {/* Render the active component */}
                    <div className="space-x-4">
                        {activeTab === 'manpower' && <AddManPowers addManPower={addManPower} />}
                        {activeTab === 'equipment' && <EquipmentAllocation addEquipment={addEquipment} />}
                    </div>
                </div>

                {/* Right side */}
                <div className="lg:col-span-2 grid grid-cols-1 gap-8 h-[calc(100vh-4rem)] overflow-y-auto bg-gray-50 pt-2 dark:bg-dark">
                    <div className="mx-3">

                        <div className="flex space-x-4 mb-2"> {/* Flex container for buttons in one line */}
                            <button
                                className={`flex-1 p-2 text-sm text-nowrap text-center  rounded-lg ${activeTable === 'manpower' ? 'bg-blue-500 text-gray-50 dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveTable('manpower')}
                            >
                                Add Manpower
                            </button>
                            <button
                                className={`flex-1 p-2 text-center text-nowrap  text-sm rounded-lg ${activeTable === 'equipment' ? 'bg-blue-500 text-gray-50 dark:bg-secondaryDark' : 'bg-gray-200 text-gray-700'}`}
                                onClick={() => setActiveTable('equipment')}
                            >
                                Equipment Allocation
                            </button>
                        </div>


                        {/* Render the active component */}
                        <div className="space-x-4">
                            {activeTable === 'manpower' && <ManpowerAssessment manpower={manpower} />}
                            {activeTable === 'equipment' && <EquipmentAllocationTable equipment={equipment} />}
                        </div>
                        {/* <ManpowerAssessment /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResourceAllocationLayout;
