import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = ({ allowedRoles }) => {
    const token = localStorage.getItem("token");
    const userRole = localStorage.getItem("userRole"); // e.g., "admin" or "customer"

    // Check if the user is authenticated and has an allowed role
    const isAuthenticated = !!token;
    const hasAccess = allowedRoles.includes(userRole);

    return isAuthenticated && hasAccess ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
