import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FaPlus, FaSpinner } from 'react-icons/fa';
import axiosInstance from '../../services/AxiosInstance';

export default function AddSupplierProduct({ addNewProduct }) {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [products, setProducts] = useState([{ supplierProduct: '', estimateDate: '' }]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch suppliers from the API
  useEffect(() => {
    const fetchSuppliers = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/backend/api/suppliers');
        const supplierOptions = response.data.map((supplier) => ({
          value: supplier.id,
          label: supplier.name,
        }));
        setSuppliers(supplierOptions);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
        setLoading(false);
        setErrorMessage('Error fetching suppliers');
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedSupplier) {
      setErrorMessage('Please select a supplier');
      return;
    }

    setLoading(true);
    try {
      const formData = {
        supplierId: selectedSupplier.value,
        products,
      };

      console.log(products);

      const response = await axiosInstance.post(`/backend/api/supplier-product/${selectedSupplier.value}`, products);

      if (response.status === 201 || response.status === 200) {
        addNewProduct(response.data);
        setSuccessMessage('Products added successfully');
        setErrorMessage('');
        setProducts([{ supplierProduct: '', estimateDate: '' }]); // Reset form
      }
    } catch (error) {
      console.error('Error adding products:', error);
      setErrorMessage('Failed to add products');
      setSuccessMessage('');
    } finally {
      setLoading(false);
    }
  };

  // Handle product input change
  const handleProductChange = (index, field, value) => {
    const newProducts = [...products];
    newProducts[index][field] = value;
    setProducts(newProducts);
  };

  // Add a new product input field
  const addProductField = () => {
    setProducts([...products, { supplierProduct: '', estimateDate: '' }]);
  };

  return (
    <div className="container mx-auto p-4 bg-gray-50 rounded-lg dark:bg-secondaryDark">
      <h1 className="text-2xl font-bold mb-4">Add Products</h1>

      {loading && <FaSpinner className="animate-spin text-indigo-500" />}

      {successMessage && (
        <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Supplier Dropdown */}
        <div>
          <label htmlFor="supplier" className="block text-sm font-medium text-gray-700 dark:text-dark">
            Select Supplier
          </label>
          <Select
            id="supplier"
            options={suppliers}
            value={selectedSupplier}
            onChange={setSelectedSupplier}
            className="mt-1"
            maxMenuHeight={150}
            isSearchable
            isClearable
            isLoading={loading}
          />
        </div>

        {/* Dynamic Product Fields */}
        {products.map((product, index) => (
          <div key={index} className="flex gap-4 mb-4">
            <div className="w-1/2">
              <label htmlFor={`product-${index}`} className="block text-sm font-medium text-gray-700 dark:text-dark">
                Product Name
              </label>
              <input
                id={`product-${index}`}
                type="text"
                value={product.supplierProduct}
                onChange={(e) => handleProductChange(index, 'supplierProduct', e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div className="w-1/2">
              <label htmlFor={`deliveryDate-${index}`} className="block text-sm font-medium text-gray-700 dark:text-dark">
                Estimated Delivery Date
              </label>
              <input
                id={`deliveryDate-${index}`}
                type="date"
                value={product.estimateDate}
                onChange={(e) => handleProductChange(index, 'estimateDate', e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
          </div>
        ))}

        {/* Add More Product Button */}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={addProductField}
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-dark dark:hover:bg-primaryDark"
          >
            <FaPlus className="mr-2" /> Add More Product
          </button>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${loading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-dark dark:hover:bg-primaryDark`}
          >
            {loading ? <FaSpinner className="animate-spin mr-2" /> : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
}
