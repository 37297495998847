import { useMemo } from "react";
import PropTypes from "prop-types";
import "./Slide.css";

const Slide = ({
  className = "",
  propBackgroundImage,
  propWidth,
  slideNumberLabels,
  propWidth1,
  propMinWidth,
  propWidth2,
  propFlexWrap,
  iconslogos,
  hitchHikeProject,
  description,
  propFlex,
  propMinWidth1,
  propWidth3,
  ctaText,
  iconArrow,
  onClick,
  showIconCtaLeadIcon,

}) => {
  const slide3Style = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage,
    };
  }, [propBackgroundImage]);

  const cardNumberingStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const slideNumberLabelsStyle = useMemo(() => {
    return {
      width: propWidth1,
      minWidth: propMinWidth,
    };
  }, [propWidth1, propMinWidth]);

  const projectDetailsStyle = useMemo(() => {
    return {
      width: propWidth2,
      flexWrap: propFlexWrap,
    };
  }, [propWidth2, propFlexWrap]);

  const hitchHikeProjectStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth1,
      width: propWidth3,
    };
  }, [propFlex, propMinWidth1, propWidth3]);

  return (
    <div className={`slide3 ${className}`} style={slide3Style}>
      <div className="slide-content">
        <div className="card-numbering" style={cardNumberingStyle}>
          <b className="slide-number-labels" style={slideNumberLabelsStyle}>
            {slideNumberLabels}
          </b>
        </div>
        <div className="project-info">
          <div className="project-details" style={projectDetailsStyle}>
            <img
              className="iconslogos"
              loading="lazy"
              alt=""
              src={iconslogos}
            />
            <h1 className="hitch-hike-project" style={hitchHikeProjectStyle}>
              {hitchHikeProject}
            </h1>
          </div>
          <div className="check-out-some">
              {description}
          </div>
        </div>
        <button className="btn-viewproject" onClick={onClick}>
          <b className="signup1" >
            {ctaText}
          </b>
          {showIconCtaLeadIcon && (
            <img
              className="icon-arrow"
              alt=""
              src={iconArrow}
            />
          )}
        </button>
      </div>
    </div>
  );
};

Slide.propTypes = {
  className: PropTypes.string,
  slideNumberLabels: PropTypes.string,
  iconslogos: PropTypes.string,
  hitchHikeProject: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  iconArrow: PropTypes.string,
  showIconCtaLeadIcon: PropTypes.bool,
  propBorder: PropTypes.string,
  propPadding: PropTypes.string,
  propWidth4: PropTypes.string,
  propBackground: PropTypes.string,
  propMarginLeft: PropTypes.string,
  propFontSize: PropTypes.string,
  propColor: PropTypes.string,
  propMinWidth2: PropTypes.string,
  propFontWeight: PropTypes.string,
  propHeight: PropTypes.string,
  propWidth11: PropTypes.string,
  propMinHeight: PropTypes.string,

  onClick: PropTypes.func.isRequired,
};

export default Slide;
