import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance'; // Assuming axiosInstance is properly configured
import QuotationModal from './QuotationModal'; // Import the new modal component

const QuotationTable = ({ quotation }) => {
  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQuotation, setSelectedQuotation] = useState(null); // Set to null initially
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    const fetchQuotations = async () => {
      try {
        const response = await axiosInstance.get('/backend/api/contact/customer');

        // Filter customers to include only those with a non-empty detailsSendList
        const filteredQuotations = response.data.filter(customer =>
          customer.detailsSendList && customer.detailsSendList.length > 0
        );

        // Sort the quotations
        const sortedQuotations = filteredQuotations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setQuotations(sortedQuotations);
        // console.log('Fetched quotations:', sortedQuotations); 

      } catch (error) {
        console.error('Error fetching quotations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuotations();
  }, []);



  useEffect(() => {
    if (quotation && quotation.length > 0) {
      setQuotations(prev => {
        const updatedQuotations = [...prev, ...quotation].filter(customer =>
          customer.detailsSendList && customer.detailsSendList.length > 0
        );
        return updatedQuotations.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort after adding new quotations
      });
    }
  }, [quotation]);



  const handleViewDetails = (quotation) => {
    setSelectedQuotation(quotation);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedQuotation(null); // Clear the selected quotation
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center gap-3  h-[calc(100vh-3.5rem)]">
        <div className="animate-spin border-t-4 rounded-full border-blue-600 h-14 w-14"></div>
        <p className='dark:text-secondaryDark' >Loading quotations</p>
      </div>
    )
  }

  return (
    <div className="max-w-6xl mx-auto p-6 bg-gray-50 rounded-lg shadow-md drop-shadow-xl dark:bg-secondaryDark ">
      <h1 className="text-3xl font-bold mb-8 text-gray-700 text-left dark:text-dark">Quotation List</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
          <thead className="bg-silver  dark:bg-primaryDark text-gray-50 ">
            <tr className='text-nowrap'>
              <th className="px-6 py-3 text-left text-md font-semibold tracking-wider">#</th>
              <th className="px-6 py-3 text-left text-md font-semibold tracking-wider">Customer Name</th>
              <th className="px-6 py-3 text-left text-md font-semibold tracking-wider">Customer Email</th>
              <th className="px-6 py-3 text-left text-md font-semibold tracking-wider">Contact No.</th>
              <th className="px-6 py-3 text-left text-md font-semibold tracking-wider">Description</th>
              <th className="px-6 py-3 text-center text-md font-semibold tracking-wider">Actions</th> {/* Added Actions column */}
            </tr>
          </thead>
          <tbody className="bg-gray-50 dark:bg-secondaryDark dark:text-dark">
            {quotations.map((quotation, index) => (
              <tr
                key={quotation.id}
                className={`border-b ${index % 2 === 0 ? 'bg-secondaryDark' : 'text-gray-700 bg-secondaryDark'} dark:text-dark text-nowrap transition-colors duration-300`}
              >
                <td className="px-6 py-4 whitespace-nowrap  font-medium text-left">{index + 1}</td>
                <td className="px-6 py-4 whitespace-nowrap  font-medium text-left">{quotation.name}</td>
                <td className="px-6 py-4 whitespace-nowrap  font-medium text-left">{quotation.email}</td>
                <td className="px-6 py-4 whitespace-nowrap  font-medium text-left">{quotation.phone}</td>

                <td className="px-6 py-4  text-left">{quotation.description}</td>
                <td className="px-6 py-4 text-center">
                  <button
                    onClick={() => handleViewDetails(quotation)} // Open modal with details
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for quotation details */}
      <QuotationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        quotationDetails={selectedQuotation || {}}
      />
    </div>
  );
};

export default QuotationTable;
