import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone, faEnvelope, faX} from '@fortawesome/free-solid-svg-icons'; 
import MapsFullMap from "./maps-full-map";
import ContactModal from "./ContactModal";
import './footer.module.css';

export default function Footer() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };

  return (
    <footer className="pt-6 px-4 md:px-8 ">
      <div className="container mx-auto max-w-7xl">
        {/* Updated grid classes for mobile-first design */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Column 1 */}
          <div>
            <div className="">
              {/* <h2 className="text-3xl font-bold">K'Team</h2>
              <p className="text-sm ">Interior Studio</p> */}
              		<img 
                    src="../images/Kala_Int_PNGLogo.png" 
                    alt="Kala Interiors Logo" 
                    style={{width:'200px',height:'150px',position:'relative',top:'-30px'}} 

                  />
            </div>
            <nav className="mb-6 " >
              <ul className="flex flex-wrap gap-4 text-blue-600 fw-bold">
                <li className="hover:underline hover:text-black">
                  <Link to="/">Home</Link>
                </li>
                <li className="hover:underline hover:text-black">
                  <Link to="/about">About</Link>
                </li>
                <li className="hover:underline hover:text-black">
                  <Link to="/#service" smooth>Services</Link>
                </li>
                <li className="hover:underline hover:text-black">
                  <Link to="/projects">Project</Link>
                </li>
              </ul>
            </nav>
            <div className="d-flex" style={{flexDirection:'column'}}>
              <div className="mb-6 ">
                <h3 className="text-lg font-semibold mb-2">Address:</h3>
                <p className="text-sm leading-relaxed">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 " />
                Office no. 1, Ground Floor, Near Madgiri Garden, Indrayani Nagar, Bhosari, Pune, Maharashtra 411026
                </p>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Contact:</h3>
               
                <p className="text-sm  mb-2">
                <FontAwesomeIcon icon={faPhone} className="mr-2 " />
                  8378989511
                
                </p>
                <p className="text-sm  mb-2">
                <FontAwesomeIcon icon={faPhone} className="mr-2" />  
                  8378989507  
                </p>        
                <p className="text-sm ">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 " />
                hemalandge@gmail.com</p>
                <p className="text-sm">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 " />
                amit.s.landge@gmail.com</p>
              </div>
              <div className="">
                <h3 className="text-lg font-semibold mb-2 ">Follow Us:</h3>
                  <div className="flex space-x-4 mb-4">
                  <a 
                      href="https://wa.me/8378989507" 
                      className="text-white hover:text-green-500 fw-bold" 
                      aria-label="WhatsApp"
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} size="lg" color="#25D366" />
                    </a>
                    {/* <a 
                      href="https://wa.me/8378989507" 
                      className="text-white hover:text-green-500" 
                      aria-label="WhatsApp"
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} size="lg" color="#25D366" />
                    </a> */}

                    <a href="/" className=" hover:text-blue-300" aria-label="Facebook">
                      <FontAwesomeIcon icon={faFacebookF} size="lg" color="#1877F2"/>
                    </a>
                    <a href="/" className=" hover:text-blue-300" aria-label="Instagram">
                      <FontAwesomeIcon icon={faInstagram} size="lg" color="#E1306C"/>
                    </a>
                    <a href="https://www.linkedin.com/in/kala-interior-a3a088333?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className=" hover:text-blue-300" aria-label="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedinIn} size="lg" color="#0077B5"/>
                    </a>
                    <a href="/" className=" hover:text-blue-300" aria-label="YouTube">
                      <FontAwesomeIcon icon={faYoutube} size="lg" color="#FF0000"/>
                    </a>
                    <a href="/" className=" hover:text-blue-300" aria-label="Twitter">
                      <FontAwesomeIcon icon={faX} size="lg" color="black"/>
                    </a>
                </div>
              </div>
            </div>
          </div>
          {/* Column 2 */}
          <div >
            <h3 className="text-xl font-semibold mb-4">Locate Us</h3>
            <div className="h-71 mb-4">
              <MapsFullMap onContactClick={openContactModal} />
            </div>
          </div>
        </div>
        <div className=" pt-2 border-t border-black">
          <div className="flex flex-col md:flex-row justify-between items-center  ">
            <p className="text-sm mb-4 md:mb-0">© 2024 Kala Interiors All rights reserved.</p>
            <div className="flex flex-wrap justify-center gap-4 text-sm items-center">
              <span className="hover:underline">Privacy Policy</span>
              <span className="hover:underline">Terms of Service</span>
              <span className="hover:underline">Cookies Settings</span>
              <span className="text-gray-400">|</span>
              <span>Designed by Ashdip IT Solutions</span>
            </div>
          </div>
         
        </div>
      </div>
      {isContactModalOpen && (
        <ContactModal onCloseContact={closeContactModal} openContact={isContactModalOpen} />
      )}
    </footer>
  );
}
