import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from "react-router-hash-link";



const NavWrapper = () => {


	const [isOpen, setIsOpen] = useState(false);
	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};


	const [menuOpen, setMenuOpen] = useState(false); // Renamed isOpen to menuOpen
	const toggleMenuMobile = () => {
		setMenuOpen(!menuOpen); // Renamed the state update logic
	};



	return (

		<div className="w-full">
			<div className="w-full h-[75px]" style={{ position: 'relative', top: '-55px' }}>
				<div className="flex justify-between items-center pe-4  ">
					{/* Logo */}
					<div className="">
						<img
							src="../images/navbar-logo.png"
							alt="Kala Interiors Logo"
							style={{ height: '180px' }}

						/>
					</div>

					{/* Menu icon for mobile */}
					<div className="md:hidden">
						<button onClick={toggleMenuMobile}>
							<svg
								className="w-6 h-6 text-black"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 6h16M4 12h16m-7 6h7"
								/>
							</svg>
						</button>
					</div>

					<div className='float-end'>
						{/* Navigation Links for desktop */}
						<div className="hidden md:flex md:gap-4 md:items-center mb-4">
							<ul className="flex flex-row gap-4 text-md font-bold font-slick leading-[21px] pt-4">
								<li>
									<NavLink
										to="/"
										className={({ isActive }) =>
											window.location.pathname === "/" && !window.location.hash
												? "p-2 text-orange-600 cursor-pointer" // Active styling
												: "p-2 text-black cursor-pointer" // Inactive styling
										}
									>
										Home
									</NavLink>
								</li>
								<li>
									<Link
										to="/#service"
										className={window.location.hash === "#service"
											? "p-2 text-orange-600 cursor-pointer" // Active styling
											: "p-2 text-black cursor-pointer" // Inactive styling
										}
									>
										Services
									</Link>
								</li>
								<li>
									<NavLink
										to="/about"
										className={({ isActive }) =>
											isActive
												? "p-2 text-orange-600 cursor-pointer" // Active styling
												: "p-2 text-black cursor-pointer" // Inactive styling
										}
									>
										About
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/projects"
										className={({ isActive }) =>
											isActive
												? "p-2 text-orange-600 cursor-pointer" // Active styling
												: "p-2 text-black cursor-pointer" // Inactive styling
										}
									>
										Projects
									</NavLink>
								</li>

							</ul>

							<NavLink
								to="/login" className="px-[22px] py-2 rounded-md border border-primary  hover:bg-black hover:text-white justify-center items-center flex cursor-pointer mt-4 ps-3">
								Login
							</NavLink>

						</div>


					</div>
				</div>
			</div>

			{/* Mobile Navigation */}
			{menuOpen && (
				<div className="md:hidden w-full bg-white shadow-md py-4 absolute top-16 right-0 z-50 ">
					<ul className="flex flex-col gap-4 text-sm font-bold font-slick leading-[21px] px-4">
						<li>
							<NavLink
								to="/"
								className="p-2 text-black cursor-pointer"
								onClick={toggleMenuMobile}

							>
								Home
							</NavLink>
						</li>
						<li>
							<Link smooth

								className="p-2 text-black cursor-pointer"
								to='/#service'
								// onClick={toggleMenu}
								onClick={toggleMenuMobile}
							>
								Services
							</Link>
						</li>
						<li>
							<NavLink
								to="/about"
								className="p-2 text-black cursor-pointer"
								onClick={toggleMenuMobile}
							>
								About
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/projects"
								className="p-2 text-black cursor-pointer "
								onClick={toggleMenuMobile}
							>
								Projects
							</NavLink>
						</li>

						{/* Login/Signup buttons for mobile */}
						<li className="flex flex-col gap-2 font-slick">
							<NavLink
								onClick={toggleMenuMobile}
								to="/login" className="px-[22px] py-2 rounded-md justify-center border border-[#0d193a] items-center flex cursor-pointer  text-black">
								Login
							</NavLink>
							{/* <NavLink
								onClick={toggleMenuMobile}
								to="/login" className="px-[22px] py-2 rounded-md justify-center border border-[#0d193a] flex items-center cursor-pointer">
								Signup
							</NavLink> */}
						</li>
					</ul>
				</div>
			)}



			{/* {isOpen && (
				<div onMouseLeave={toggleMenu} className="w-[80%] h-[auto]  bg-white rounded-md shadow justify-start items-start flex flex-row absolute top-18 left-0 z-50  ">
					<div className="grow shrink basis-0 h-[351.67px] pl-[56.89px] pr-[28.44px] py-[28.44px] justify-start items-start gap-[28.44px] flex">
						<div className="grow shrink basis-0 flex-col justify-start items-start gap-[14.22px] inline-flex">
							<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-[21px] tracking-tight">Services</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page One</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Two</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Three</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Four</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
						</div>
						<div className="grow shrink basis-0 flex-col justify-start items-start gap-[14.22px] inline-flex">
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Five</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Six</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Seven</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[10.67px] inline-flex">
								<div className="w-[21.33px] h-[21.33px] px-[2.67px] py-[1.78px] justify-center items-center flex" />
								<div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
									<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Page Eight</div>
									<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet consectetur elit</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-[497.78px] self-stretch pl-[28.44px] pr-[85.33px] py-[28.44px] bg-[#eeeeee] flex-col justify-start items-start gap-[14.22px] inline-flex">
						<div className="self-stretch text-black text-xs font-bold font-['Satoshi Variable'] leading-[18px] tracking-tight">Featured Projects</div>
						<div className="self-stretch h-[222.22px] flex-col justify-start items-start gap-[7.11px] flex">
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[21.33px] inline-flex">
								<img className="w-[142.22px] h-[93.33px]" src="https://via.placeholder.com/142x93" alt="Project 1" />
								<div className="grow shrink basis-0 flex-col justify-start items-start gap-[7.11px] inline-flex">
									<div className="self-stretch h-[62.56px] flex-col justify-start items-start gap-[3.56px] flex">
										<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Article Title</div>
										<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
									</div>
									<div className="text-black text-xs font-normal font-['Satoshi Variable'] underline leading-[18.67px]">Read more</div>
								</div>
							</div>
							<div className="self-stretch py-[7.11px] justify-start items-start gap-[21.33px] inline-flex">
								<img className="w-[142.22px] h-[93.33px]" src="https://via.placeholder.com/142x93" alt="Project 2" />
								<div className="grow shrink basis-0 flex-col justify-start items-start gap-[7.11px] inline-flex">
									<div className="self-stretch h-[62.56px] flex-col justify-start items-start gap-[3.56px] flex">
										<div className="self-stretch text-black text-sm font-medium font-['Satoshi Variable'] leading-snug">Article Title</div>
										<div className="self-stretch text-black text-xs font-normal font-['Satoshi Variable'] leading-[18.67px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
									</div>
									<div className="text-black text-xs font-normal font-['Satoshi Variable'] underline leading-[18.67px]">Read more</div>
								</div>
							</div>
						</div>
						<div className="py-[3.56px] justify-center items-center gap-[7.11px] inline-flex">
							<div className="text-black text-base font-normal font-['Roboto'] leading-normal">See all Projects</div>
							<div className="w-[21.33px] h-[21.33px] pl-[7.37px] pr-[7.63px] pt-[4.92px] pb-[6.27px] justify-center items-center flex" />
						</div>
					</div>
				</div>
			)} */}



		</div>
	);
};

export default NavWrapper;
