import React from 'react'
import './AboutHeader.css'


const AboutHeader = () => {

    return (
        <>
            <div className="about-section">

            <div className="about-header">
                <div className="about-heading-wrapper">
                <b className="about-title">About</b>
                <div className="icon-container">
                     <img className="icons" alt="" src="/images/star-icons.svg" />
                     <img className="icons" alt="" src="/images/frame-1.svg" />
                     <img className="icons" alt="" src="/images/frame-2.svg" />
                     <img className="icons" alt="" src="/images/frame-3.svg" />
                     <img className="icons" alt="" src="/images/frame-4.svg" />
                </div>
                </div>
            </div>
            <div className="about-content">
                <div className="intro-wrapper">
                <h2 className="intro-title">A Design Team from Pune, India</h2>
                <p className="intro-description">
                    Our team of experienced interior designers will transform your home or office into a beautiful, functional space that reflects your unique style.
                </p>
                </div>
            </div>
            </div>

            <div className="quote-container">
            <div className="quote-background">
                <blockquote className="quote-text">
                <span className="quote-start">“</span>
                We Believe & Work on
                <br />
                <span className="quote-highlight">Creating Balance & Harmony</span>
                <br />
                within Living Spaces
                <br />
                To Make your Every Day
                <span className="quote-end">”</span>
                </blockquote>
                <div className="quote-subtext">
                <i>Prosperous & Energetic</i>
                </div>
            </div>
            </div>
        </>
    )
}

export default AboutHeader