import React from 'react';

const goals = [
  { id: 1, description: 'Complete project X', status: 'In Progress' },
  { id: 2, description: 'Improve customer satisfaction score', status: 'Completed' },
  { id: 3, description: 'Reduce response time by 20%', status: 'Not Started' },
];

const PerformanceGoals = () => {
  return (
    <div className="bg-gray-50 dark:bg-secondaryDark shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Performance Goals</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className=" text-gray-700 dark:text-dark">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200">
            {goals.map((goal) => (
              <tr key={goal.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{goal.description}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      goal.status === 'Completed'
                        ? 'bg-green-100 text-green-800'
                        : goal.status === 'In Progress'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {goal.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PerformanceGoals;
