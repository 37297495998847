import React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

const EmployeeNavbar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token'); 
        localStorage.removeItem('userRole'); 
        localStorage.removeItem('email');
        navigate('/login');
    };

    return (
        <div className="w-full">
            <nav className="w-full bg-gray-50 z-50 h-[120px] fixed" style={{ position: 'fixed', top: '-55px' }}>
                <div className="flex justify-between items-center pe-4">
                    <div className="">
                        <img
                            src="../images/navbar-logo.png"
                            alt="Kala Interiors Logo"
                            style={{ height: '180px' }}
                        />
                    </div>
                    <button
                        data-collapse-toggle="navbar-solid-bg"
                        type="button"
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-solid-bg"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    </button>
                    <div className="hidden w-full md:block md:w-auto" id="navbar-solid-bg">
                        <ul className="flex flex-col font-medium rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                            <li>
                                <NavLink
                                    to="/employee/HRM-Attendence"
                                    className={({ isActive }) =>
                                        isActive
                                            ? "block py-2 px-3 md:p-0 text-blue-700 font-semibold"
                                            : "block py-2 px-3 md:p-0 text-gray-900 hover:text-blue-700 dark:text-white"
                                    }
                                >
                                    Attendence & Leaves
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/employee/HRM-Payroll"
                                    className={({ isActive }) =>
                                        isActive
                                            ? "block py-2 px-3 md:p-0 text-blue-700 font-semibold"
                                            : "block py-2 px-3 md:p-0 text-gray-900 hover:text-blue-700 dark:text-white"
                                    }
                                >
                                    Payroll & Payslips
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/employee/HRM-Performance"
                                    className={({ isActive }) =>
                                        isActive
                                            ? "block py-2 px-3 md:p-0 text-blue-700 font-semibold"
                                            : "block py-2 px-3 md:p-0 text-gray-900 hover:text-blue-700 dark:text-white"
                                    }
                                >
                                    Performance & Goals
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/employee/Profile"
                                    className={({ isActive }) =>
                                        isActive
                                            ? "block py-2 px-3 md:p-0 text-blue-700 font-semibold"
                                            : "block py-2 px-3 md:p-0 text-gray-900 hover:text-blue-700 dark:text-white"
                                    }
                                >
                                    Profile
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/contact"
                                    className={({ isActive }) =>
                                        isActive
                                            ? "block py-2 px-3 md:p-0 text-blue-700 font-semibold"
                                            : "block py-2 px-3 md:p-0 text-gray-900 hover:text-blue-700 dark:text-white"
                                    }
                                >
                                    Contact
                                </NavLink>
                            </li>
                            <li>
                                <button
                                    onClick={handleLogout}
                                    className="block py-2 px-3 md:p-0 text-gray-900 hover:text-red-700 dark:text-white"
                                >
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet />
        </div>
    );
};

export default EmployeeNavbar;
