import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Select from 'react-select';

const PurchesOrder = ({ addOrder }) => {
    const [newOrder, setNewOrder] = useState({
        materials: [], // Store materials with their quantity and unit price
        forecastPeriod: { start: "", end: "" },
        supplier: "",
        deliveryDate: "",
        status: "Pending",
    });

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [suppliers, setSuppliers] = useState([]); // State for supplier options
    const [filteredMaterials, setFilteredMaterials] = useState([]); // State for filtered materials

    // Fetch suppliers from backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                const supplierRes = await axiosInstance.get('/backend/api/suppliers');  // Fetch supplier list
                const supplierOptions = supplierRes.data.map((supplier) => ({
                    value: supplier.id,
                    label: supplier.name,
                    materials: supplier.supplierProducts // Store related materials
                }));
                setSuppliers(supplierOptions);
            } catch (error) {
                console.error("Error fetching suppliers:", error);
                setErrorMessage("Failed to fetch suppliers.");
            }
        };

        fetchData();
    }, []);

    const handleSupplierChange = (selectedSupplier) => {
        const supplierMaterials = selectedSupplier.materials.map(material => ({
            value: material.id,
            label: material.supplierProduct
        }));

        setFilteredMaterials(supplierMaterials); // Update material dropdown options

        setNewOrder({
            ...newOrder,
            supplier: selectedSupplier.value,
            materials: [] // Reset material selection
        });
    };

    // Handle material selection with individual quantity and unit price
    const handleMaterialChange = (selectedMaterials) => {
        // Map over selected materials and retain any existing quantity and unitPrice values
        const updatedMaterials = selectedMaterials.map(material => {
            const existingMaterial = newOrder.materials.find(m => m.value === material.value);
            return {
                ...material,
                quantity: existingMaterial ? existingMaterial.quantity : 0, // Retain existing quantity or default to 0
                unitPrice: existingMaterial ? existingMaterial.unitPrice : 0, // Retain existing unit price or default to 0
            };
        });

        setNewOrder({ ...newOrder, materials: updatedMaterials });
        calculateTotalAmount(updatedMaterials);
    };


    const handleQuantityChange = (index, value) => {
        const updatedMaterials = [...newOrder.materials];
        updatedMaterials[index].quantity = Math.max(0, value); // Ensure no negative quantity
        setNewOrder({ ...newOrder, materials: updatedMaterials });
        calculateTotalAmount(updatedMaterials);
    };

    const handleUnitPriceChange = (index, value) => {
        const updatedMaterials = [...newOrder.materials];
        updatedMaterials[index].unitPrice = Math.max(0, value); // Ensure no negative price
        setNewOrder({ ...newOrder, materials: updatedMaterials });
        calculateTotalAmount(updatedMaterials);
    };

    const calculateTotalAmount = (materials) => {
        const total = materials.reduce((sum, material) => {
            return sum + (material.quantity * material.unitPrice);
        }, 0);
        setTotalAmount(total);
    };

    const handleOrderSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccessMessage("");
        setErrorMessage("");

        const newOrderData = {
            materials: newOrder.materials.map(material => ({
                productId: material.value, // Assuming 'value' holds product ID
                quantity: material.quantity,
                unitPrice: material.unitPrice
            })),
            orderDate: new Date().toISOString().split('T')[0],
            expectedDeliveryDate: newOrder.deliveryDate,
            totalAmount: totalAmount,
            status: newOrder.status,
        };

        console.log(newOrderData);
        
        try {
            const response = await axiosInstance.post(`/backend/api/purchase-orders/${newOrder.supplier}/material`,
                newOrderData 
            );

            addOrder(response.data);
            setSuccessMessage("Purchase order created successfully!");
            setNewOrder({
                materials: [],
                forecastPeriod: { start: "", end: "" },
                supplier: "",
                deliveryDate: "",
                status: "Pending",
            });
            setTotalAmount(0);
        } catch (error) {
            console.error("There was an error creating the purchase order:", error);
            setErrorMessage("Failed to create the purchase order. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    const colorMap = {
        0: '#FF5A5F', // Red for the first selected label
        1: '#2ECC71', // Green for the second selected label
        2: '#3498DB', // Blue for the third selected label
        3: '#F1C40F', // Yellow for the fourth selected label
        4: '#E67E22', // Orange for the fifth selected label
        // Add more colors as needed
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#f0f4f8" : "#fff",
            borderColor: state.isFocused ? "#4A90E2" : "#ccc",
            boxShadow: state.isFocused ? "0 0 0 1px #4A90E2" : "none",
            "&:hover": { borderColor: "#4A90E2" }
        }),
        multiValue: (provided, state) => {
            const index = state.index || 0;
            const color = colorMap[index % Object.keys(colorMap).length];
            return {
                ...provided,
                backgroundColor: color, // Apply dynamic background color
            };
        },
        multiValueLabel: (provided, state) => {
            const index = state.index || 0;
            const color = colorMap[index % Object.keys(colorMap).length];
            return {
                ...provided,
                color: "#fff", // White text for better contrast
            };
        },
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: "#fff", // Keep the remove icon white
            "&:hover": {
                backgroundColor: "#D0021B",
                color: "#fff",
            },
        }),
    };

    return (
        <div className="bg-gray-50 p-4 rounded-lg shadow-lg dark:bg-secondaryDark">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-dark">Purchase Order Generation</h2>

            {successMessage && <div className="p-2 bg-green-100 text-green-800 rounded-md mb-4">{successMessage}</div>}
            {errorMessage && <div className="p-2 bg-red-100 text-red-800 rounded-md mb-4">{errorMessage}</div>}
            {loading && <div className="text-center mb-4">Submitting your order...</div>}

            <form onSubmit={handleOrderSubmit} className="space-y-4 text-gray-700 dark:text-dark">
                <div>
                    <label htmlFor="supplier" className="block text-sm font-medium ">Supplier</label>
                    <Select
                        id="supplier"
                        value={suppliers.find(option => option.value === newOrder.supplier)}
                        onChange={handleSupplierChange}
                        options={suppliers}
                        placeholder="Select supplier"
                        className="mt-1 block w-full"
                    />
                </div>

                <div>
                    <label htmlFor="materials" className="block text-sm font-medium ">Material Name</label>
                    <Select
                        id="materials"
                        value={newOrder.materials}
                        onChange={handleMaterialChange}
                        options={filteredMaterials}
                        placeholder="Select materials"
                        isClearable
                        isMulti
                        styles={customStyles}
                        className="mt-1 block w-full"
                        isDisabled={!newOrder.supplier}
                    />
                </div>

                {newOrder.materials.map((material, index) => (
                    <div key={material.value} className="border-t mt-2 pt-2">
                        <h4 className="font-semibold ">{material.label}</h4>
                        <div className="flex gap-4 mt-2">
                            <div>
                                <label htmlFor={`quantity-${index}`} className="block text-sm font-medium ">Quantity</label>
                                <input
                                    type="number"
                                    id={`quantity-${index}`}
                                    value={material.quantity}
                                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor={`unitPrice-${index}`} className="block text-sm font-medium ">Unit Price</label>
                                <input
                                    type="number"
                                    id={`unitPrice-${index}`}
                                    value={material.unitPrice}
                                    onChange={(e) => handleUnitPriceChange(index, e.target.value)}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                ))}

                <div>
                    <label htmlFor="deliveryDate" className="block text-sm font-medium ">Delivery Date</label>
                    <input
                        type="date"
                        id="deliveryDate"
                        value={newOrder.deliveryDate}
                        onChange={(e) => setNewOrder({ ...newOrder, deliveryDate: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div>
                    <label htmlFor="status" className="block text-sm font-medium ">Purchase Order Status</label>
                    <select
                        id="status"
                        value={newOrder.status}
                        onChange={(e) => setNewOrder({ ...newOrder, status: e.target.value })}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="Pending">Pending</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Cancelled">Cancelled</option>
                    </select>
                </div>

                <div className="flex justify-between items-center">
                    <span className="font-medium">Total Amount: ${totalAmount.toFixed(2)}</span>
                </div>

                <button
                    type="submit"
                    className={`mt-4 w-full font-semibold py-2 rounded-md ${loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-indigo-600 text-white dark:bg-dark dark:hover:bg-primaryDark'}`}
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : 'Generate Purchase Order'}
                </button>
            </form>
        </div>
    );
};

export default PurchesOrder;
